import { useState, useEffect } from "react";
import Slider from "react-slick";
import "./Css/Testimonial.css";
import testiMonialImg from "../Assets/testiMonialImg.png";
import {
  APL_LINK,
  server_post_data,
  get_all_testimonial_website,
} from "../ServiceConnection/serviceconnection";
export default function Testimonial() {
  const [testiData, settestiData] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const testimonials = [
    {
      quote:
        "dFoodo enabled me to restart my operations post-COVID when I had no hope of doing my business again. I'm grateful to the platform for helping me thrive - my online ordering business has done so well, it has even taken over my dining business!",
      name: "Arshad Khan",
      title: "Owner - Khushboo Biryani, Shillong",
      img: testiMonialImg,
    },
    {
      quote:
        "Thanks to dFoodo's invaluable support, our startup cloud kitchen has been doing wonders in the competitive food industry landscape. Their dedication to promoting local businesses and powerful reporting tools have been instrumental in our success, and we look forward to a long-term partnership.",
      name: "Vijay",
      title: "Owner - Birgo, Coimbatore",
      img: testiMonialImg,
    },
    {
      quote:
        "dFoodo helped us grow by 60% since registration, and now, we are one of the biggest vegetarian joints in Ernakulam city.",
      name: "Sandeep K Mohan",
      title: "Owner - Mysore Raman Idli, Kerala",
      img: testiMonialImg,
    },
  ];

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    await server_post_data(get_all_testimonial_website, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          console.log(Response.data.message);
          settestiData(Response.data.message.data_testimonial);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);
  console.group("testiData", testiData);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="testimonial-container">
      <h2 className="text-center ">Restaurant success stories</h2>
      <div className="container">
        <Slider {...settings}>
          {testiData.map((testimonial, index) => (
            <div key={index}>
              {" "}
              <div className="p-3">
                <div className="card  rounded minHeightClss">
                  <div className="card-body flxxClss">
                    <div>
                      {" "}
                      <p className="card-text discribtionTest">
                        {testimonial.message}
                      </p>
                    </div>

                    <div className="d-flex align-items-center  borderClss">
                      <img
                        // src={testimonial.img}
                        src={
                          APL_LINK + "/assets/" + testimonial.testimonial_image
                        }
                        alt={testimonial.name}
                        className="rounded me-3 testiAutherImg"
                        width="100"
                        height="50"
                      />
                      <div>
                        <h6 className="mb-0">{testimonial.auth_name}</h6>
                        <small className="text-muted">
                          {testimonial.restaurant_name} - {testimonial.city}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

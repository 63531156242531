import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Mobilefotter from "./Mobilefotter.js";
import DOMPurify from "dompurify";
import "../Components/Css/TermsOfuse.css";
import rightTickGreen from "../Assets/rightTickGreen.png";
import circleIcn from "../Assets/circleIcn.svg";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import {
  server_post_data,
  get_terms_privacy_data,
} from "../ServiceConnection/serviceconnection";
const PatnerPrivacyPolicy = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [terms, setTerms] = useState();
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_terms_privacy_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setTerms(Response.data.message[0].terms_condition);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);
  return (
    <div className="howitworks_wrapper">
      <Header />
      <div className=" container-lg headerSection_howitWorks col-lg-12">
        <div className="terms_imageContainer">
          <h3>Dfoodo Partner Privacy Policy</h3>
        </div>
      </div>
      <div className="container-lg">
        <div className="ContrrTnC">
          {" "}
          <div className="headingAgremnt">
            <h3>Last Updated: [01/01/2025]</h3>
          </div>
          <div className="topContnt">
            <p>
              Dfoodo Technologies Pvt Ltd ("Dfoodo," "we," "our," or "us") is
              committed to protecting the privacy of our partner restaurants and
              their representatives. This
              <span> Privacy Policy for Partners</span>outlines how we collect,
              use, store, process, and disclose personal and business
              information when you register and use our services as a
              <span>Dfoodo Partner Restaurant.</span>
            </p>
            <p>
              By using Dfoodo's services, including the Partner Dashboard,
              Mobile App, and Reservation Management System, you agree to the
              terms of this Privacy Policy. If you do not agree with these
              terms, you should discontinue using our platform.
            </p>
          </div>
          <div className="centerConttntt">
            <h2>1. Information We Collect</h2>
            We collect and process the following types of information to provide
            our services effectively
            <p>A. Business Information</p>
            <ul>
              <li>Restaurant Name</li>
              <li>
                Business Registration Details (e.g., GSTIN, FSSAI, Incorporation
                Certificate)
              </li>
              <li>Business Address(es)</li>
              <li>Contact Information (Email, Phone, Website, Social Media)</li>
              <li>Operating Hours</li>
              <li>Reservation & Seating Capacity</li>
              <li>Menu & Pricing Details</li>
              <li>Bank Account or UPI ID for Payments</li>
            </ul>
            <p>B. Personal Information</p>
            <ul>
              <li>Owner's/Manager's Name</li>
              <li>Email Address</li>
              <li>Phone Number</li>
              <li>Government-Issued ID (if required for verification)</li>
            </ul>
            <p>C. Technical & Device Information</p>
            <ul>
              <li>IP Address</li>
              <li>Device Identifiers (if accessing via Mobile App)</li>
              <li>Browser Type & Version</li>
              <li>App Usage Statistics</li>
              <li>GPS Location (if enabled for reservation tracking)</li>
            </ul>
            <p>D. Payment & Financial Information</p>
            <ul>
              <li>Subscription Payment Details</li>
              <li>Billing Address</li>
              <li>Transaction History</li>
            </ul>
            <p>E. Operational Data</p>
            <ul>
              <li>Customer Reservation Data</li>
              <li>Customer Feedback & Ratings</li>
              <li>Sales and Performance Reports</li>
              <li>Communication with Customers via the Platform</li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>2. How We Use Your Information</h2>

            <ul>
              We collect and use your data for the following purposes:
              <li>
                <span>Account Creation & Management:</span>
                To set up and maintain your Partner Account on Dfoodo.
              </li>
              <li>
                <span>Reservation Processing:</span>
                To facilitate online reservations, cancellations, and
                confirmations.
              </li>
              <li>
                <span>Customer Interaction:</span>
                Enabling customer communication via the platform.
              </li>
              <li>
                <span>Performance Analytics:</span>
                Generating insights on reservations, order trends, and customer
                behavior.
              </li>
              <li>
                <span>Billing & Payment Processing:</span>
                Managing subscription payments and invoices.
              </li>
              <li>
                <span>Marketing & Promotions:</span>
                Sending updates on platform improvements, promotional offers,
                and new services.
              </li>
              <li>
                <span>Fraud Prevention & Security:</span>
                Detecting and preventing fraudulent transactions and
                unauthorized access.
              </li>
              <li>
                <span>Legal & Compliance Requirements:</span>
                Adhering to local laws and regulations.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>3. Information Sharing & Disclosure</h2>We do not sell or rent
            your data. However, we may share your data under the following
            circumstances:
            <p>A. With Customers</p>
            <ul className="listStylineNonee">
              <li>
                Your restaurant name, location, menu, and reservation
                availability are publicly visible to customers on the Dfoodo
                platform.
              </li>
              <li>
                Customers can view reservation confirmation details and
                communicate directly with the restaurant.
              </li>
            </ul>
            <p>B. With Service Providers</p>
            <ul>
              We work with third-party vendors to improve our services. These
              may include:
              <li>
                Payment Processors (for secure online payments and automatic
                renewals)
              </li>
              <li>
                SMS & Email Communication Providers (for customer notifications)
              </li>
              <li>Cloud Hosting & Data Storage Providers</li>
              <li>Analytics & Business Intelligence Tools</li>
            </ul>
            <p>C. For Legal Compliance</p>
            <ul>
              <li>
                If required by law enforcement, government authorities, or
                regulatory bodies.
              </li>
              <li>
                In case of fraud detection, disputes, or any legal claims.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>4. Data Storage & Security Measures</h2>
            <p>
              We take industry-standard security measures to protect your data
            </p>
            <ul>
              <li>
                <span>Encryption:</span>
                Sensitive data is encrypted in transit and at rest.
              </li>
              <li>
                <span>Access Controls:</span>
                Only authorized personnel can access your information.
              </li>
              <li>
                <span>Regular Audits:</span>
                Security audits are conducted to detect vulnerabilities.
              </li>
              <li>
                <span>Secure Payment Processing:</span>
                We comply with PCI-DSS standards to protect financial
                transactions.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>5. Data Retention Policy</h2>
            <p>
              We retain partner information as long as you have an active
              subscription with Dfoodo. If you terminate your partnership, we:
            </p>
            <ul>
              <li>
                Retain financial transaction records for tax and legal
                compliance.
              </li>

              <li>
                Remove personal data upon request, subject to regulatory
                requirements.
              </li>
              <li>Retain anonymized analytics for business insights.</li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>6. Your Rights & Choices</h2>
            <p>As a Dfoodo partner, you have the following rights:</p>
            <ul>
              <li>
                <span>Access Your Data:</span>
                Request a copy of the personal information we store.
              </li>

              <li>
                <span>Modify or Correct Data:</span>
                Update or edit your account details.
              </li>
              <li>
                {" "}
                <span>Delete Your Account:</span>
                Request permanent deletion of your data, subject to compliance
                requirements.
              </li>
              <li>
                {" "}
                <span>Opt-Out of Marketing Emails:</span>
                Unsubscribe from promotional emails at any time.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>7. Automatic Renewal & Subscription Policies</h2>

            <ul>
              <li>
                <span>Automatic Renewals:</span>
                RYour annual subscription will be renewed automatically unless
                you opt out before the renewal date.
              </li>

              <li>
                <span>Payment Methods:</span>
                Payments will be processed via UPI, credit card, or other
                methods selected in your Partner Dashboard.
              </li>
              <li>
                {" "}
                <span>Refunds:</span>
                No refunds will be issued once the subscription payment has been
                processed.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>8. Partner Responsibilities</h2>

            <ul>
              <li>
                <span>Data Accuracy:</span>
                You must ensure that the restaurant details provided on the
                platform are accurate and up to date.
              </li>

              <li>
                <span>Customer Privacy:</span>
                Any customer data accessed via Dfoodo (e.g., reservation
                details) should be handled securely and not shared with
                unauthorized third parties.a
              </li>
              <li>
                {" "}
                <span>Compliance with Local Laws:</span>
                You must comply with local business regulations, taxation, and
                data protection laws.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>8. Termination & Data Deletion</h2>
            <p>If you choose to discontinue using Dfoodo:</p>
            <ul>
              <li>
                Provide
                <span> 30 days' written notice</span>
                to terminate the partnership.
              </li>

              <li>
                Data deletion requests can be made via
                <span>
                  <a href="mailto:support@triosoft.ai">support@triosoft.ai</a>
                </span>
                or via the Partner Dashboard.
              </li>
              <li>
                Your access to the Partner Dashboard will be revoked after the
                subscription period ends.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>10. Legal Jurisdiction</h2>
            <p>
              Any legal disputes or claims arising from the use of Dfoodo
              Partner Services will be subject to the jurisdiction of
              <span>Bhopal, India.</span>
            </p>
          </div>
          <div className="centerConttntt">
            <h2>11. Updates to This Policy</h2>
            <p>
              Dfoodo reserves the right to modify this Privacy Policy at any
              time. Updates will be notified via email or within the Partner
              Dashboard. Continued use of the platform after modifications
              implies acceptance of the revised policy.
            </p>
          </div>
          <div className="centerConttntt3">
            <h2>12. Contact Information</h2>
            <p className="firstPteg">
              If you have any questions about this Privacy Policy or need
              assistance, please contact us:
            </p>
            <div className="contact-info">
              <div className="emailLbll">
                <a
                  href="mailto:support@triosoft.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={13} /> Email :{" "}
                  <span> support@triosoft.ai </span>
                </a>
              </div>
              <div className="emailLbll">
                <a
                  href="tel:+917880016622"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPhone size={13} /> Phone : <span> +91 7880016622 </span>
                </a>
              </div>
              <div className="emailLbll">
                {" "}
                <div className="col-md-7">
                  <a
                    href="https://www.google.com/maps?q=Triosoft+Technologies+Pvt+Ltd,+Bhopal,+India"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaMapMarkerAlt size={13} /> Office Address:{" "}
                    <span>
                      Triosoft HQ, 2nd Floor, Gargi Rani Complex, Plot no. 96,
                      opposite Indian Overseas Bank, Near Raymond Showroom,
                      Zone-II, Maharana Pratap Nagar, Bhopal, Madhya Pradesh
                      462011
                    </span>
                  </a>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Mobilefotter />
      <Footer />
    </div>
  );
};

export default PatnerPrivacyPolicy;

import React, { useState, useEffect } from "react";
import searchIcon from "../Assets/searchIcon.svg.svg";
import "./Css/SearchBar.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { Link, useLocation } from "react-router-dom";
import {
  handleError,
  handleIaphabetnumberChange,
  handleLinkClick,
} from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  get_search_bar,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
const SearchBar = () => {
  const [productpath, setproductpath] = useState("");

  const [isSearchActive, setisSearchActive] = useState(false);
  const [newproducts, setnewproducts] = useState([]);
  const [SEOloop, setSEOloop] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/restro/restro_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }
    const newTimeout = setTimeout(() => {
      if (searchText.trim().length > 2) {
        get_all_search_data(1);
      } else {
        setisSearchActive(false);
      }
    }, 500);
    setSearchTimeout(newTimeout);
    return () => {
      if (newTimeout) {
        clearTimeout(newTimeout);
      }
    };
  }, [searchText]);

  const get_all_search_data = async (flag) => {
    let Data = new FormData();
    Data.append("search_data", searchText);
    Data.append("flag", flag);
    await server_post_data(get_search_bar, Data)
      .then(async (Response) => {
        console.log(Response.data.message);
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          setSEOloop(Response.data.message.seo_loop);
          setnewproducts(Response.data.message.search_fields);
          setproductpath(APL_LINK + Response.data.message.data_admin_image);
          setisSearchActive(true);
        }
      })
      .catch((error) => {
        console.log(error);
        handleError("Something Went Wrong");
      });
  };
  //mobile condition
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const locationn = useLocation();
  return (
    <>
      <div className="searchBar_wrapper">
        <div className="locBtn">
          <div className="col-10">
            <div
              className="seachVenue_section_searchbar SErchBarrr"
              style={
                locationn.pathname.includes("restro_detail") ||
                locationn.pathname.includes("catagory_detail")
                  ? {
                      border: "1px solid grey",
                      marginRight: "15rem",
                    }
                  : {}
              }
            >
              <img src={searchIcon} alt="search icon" />
              <input
                className="form-control"
                placeholder="Restaurant Name"
                type="text"
                maxLength={30}
                onInput={handleIaphabetnumberChange}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </div>
          </div>
          <div
            className="letsgo_button"
            style={
              locationn.pathname.includes("restro_detail") ||
              (locationn.pathname.includes("catagory_detail") && !isMobile)
                ? {
                    marginRight: "4rem",
                  }
                : {}
            }
          >
            <button>Let's go</button>
          </div>
        </div>
      </div>
      {isSearchActive && (
        <div className="drop_down_searchBar searchBar_wrapper Searchedata ShowResultSerach">
          <div className="col-10">
            <div className="searchItems">
              {newproducts.map((item, index) => {
                return (
                  <Link
                    key={index}
                    onClick={() =>
                      handleLinkClick(
                        match_and_return_seo_link(item.restaurant_id)
                      )
                    }
                  >
                    <div className="itemSearch">
                      <img
                        src={`${productpath}${item.restaurant_image}`}
                        alt={item.restaurant_name}
                      />
                      <div className="search_result_text">
                        <h6>{item.restaurant_name}</h6>
                        <p>{item.restaurant_full_adrress}</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
              {newproducts && newproducts.length === 0 && (
                <div className="itemSearch center_justify">
                  <div className="NoDataFOund">
                    <h6>No Data Found</h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBar;

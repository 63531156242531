import React from "react";
import "../Components/Css/DownloadApp.css";
import appStore from "../Assets/appStore.png";
import playStore from "../Assets/googlepPlay.png";
import phoneimg from "../Assets/phoenImage.png";

const DownloadApp = () => {
  // Function to handle opening the store link in a new tab
  const openAppStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=in.triosoft.www.dfoodo",
      "_blank"
    );
  };

  const openPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=in.triosoft.www.dfoodo",
      "_blank"
    );
  };

  return (
    <section className="download_now_section">
      <div className="container-lg MobileViewPd">
        <div className="phoneContainer row m-0">
          <div className="col-sm-5 p-0 leftSection">
            <img src={phoneimg} alt="phone" />
          </div>
          <div
            className="col-sm-7 pl-0 rightSection"
            style={{ backgroundColor: "var(--orange-Color)" }}
          >
            {" "}
            {/* <h3>Get the Dfoodo App</h3> */}
            <h3>Your Gateway to Effortless Dining</h3>
            <p className="mxWidthClss">
              Download now and explore top restaurants, book tables instantly,
              and enjoy exclusive offers – all at your fingertips!
            </p>
            <span className="playStore_section">
              {/* Use onClick to trigger the functions */}
              <img
                src={appStore}
                alt="appstore"
                onClick={openAppStore}
                style={{ cursor: "pointer" }}
              />
              <img
                src={playStore}
                alt="playstore"
                onClick={openPlayStore}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DownloadApp;

import React from "react";
import "./Css/AboutUs.css";
import Header from "./Header";
import Mobilefotter from "./Mobilefotter.js";
import image from "../Assets/aboutus.png";
import Footer from "./Footer";
import DownloadApp from "./DownloadApp";
import AreYouAVenueOwner from "./AreYouAVenueOwner";
import rightTickGreen from "../Assets/rightTickGreen.png";
const AboutUs = () => {
  return (
    <>
      <Header />

      <section className="section_wrapper_aboutus">
        <div className="about-us-background">
          <div className="about-us-overlay">
            <div className="container">
              <div className="row ">
                <div className="col-lg-8 about-us-content">
                  <h1>Welcome to Dfoodo! 🍽️</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="heading_aboutus">
          <div className="container-lg">
            <div className="row text-section-aboutus">
              <div className="col-md-7">
                <h3>About Us</h3>
                <p>
                  At Dfoodo, we believe that dining out should be an effortless,
                  enjoyable, and rewarding experience. Whether you’re craving a
                  cozy café vibe, a fine-dining indulgence, or a quick bite at
                  your favorite eatery, Dfoodo is your go-to platform for
                  seamless restaurant reservations, exclusive deals, and
                  personalized dining experiences.
                </p>
              </div>
              <div className="col-md-5 right_section_image_aboutus">
                <img src={image} alt="dfoodo" />
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-12">
                <p className="aboutContetn">
                  Dfoodo is a next-generation restaurant reservation platform
                  designed to connect food lovers with the best dining
                  experiences in town. We eliminate the hassle of long waiting
                  times, last-minute bookings, and restaurant availability
                  concerns. With our smart booking technology, users can reserve
                  a table in seconds, unlock special offers, and enjoy a smooth,
                  stress-free dining journey.
                </p>
                <p className="aboutContetn">
                  Our mission is simple: To revolutionize the way people
                  experience dining, making it smarter, more convenient, and
                  rewarding.{" "}
                </p>
              </div>
            </div>
            <div className="whyChooseDiv">
              <h3>Why Choose Dfoodo?</h3>
              <div className="grenMArkicnnn">
                <img src={rightTickGreen} alt="circleIcn"></img>{" "}
                <span>Seamless Restaurant Reservations </span>
                <label>
                  - Book a table instantly at top restaurants, anytime,
                  anywhere.
                </label>
              </div>
              <div className="grenMArkicnnn">
                <img src={rightTickGreen} alt="circleIcn"></img>{" "}
                <span> Zero Extra Fees</span>
                <label>
                  - No hidden charges, no extra costs – just pure dining joy.
                </label>
              </div>
              <div className="grenMArkicnnn">
                <img src={rightTickGreen} alt="circleIcn"></img>{" "}
                <span>Exclusive Discounts & Offers </span>
                <label>
                  – Enjoy flat up to 50% off on your food bills at partner
                  restaurants.
                </label>
              </div>
              <div className="grenMArkicnnn">
                <img src={rightTickGreen} alt="circleIcn"></img>{" "}
                <span>Personalized Recommendations</span>
                <label>
                  – Discover new cuisines, trending hotspots, and top-rated
                  dining places.
                </label>
              </div>
              <div className="grenMArkicnnn">
                <img src={rightTickGreen} alt="circleIcn"></img>{" "}
                <span>AI-Driven Experience </span>
                <label>
                  – Get AI-powered suggestions based on your preferences and
                  dining history.
                </label>
              </div>
              <div className="grenMArkicnnn">
                <img src={rightTickGreen} alt="circleIcn"></img>{" "}
                <span>Real-Time Table Availability</span>
                <label>
                  – Check live availability and avoid waiting in queues.
                </label>
              </div>
            </div>
            <div className="howItWorkDiv">
              <h3>How It Works?</h3>

              <ol>
                <li>
                  <span>Search & Explore</span>
                  <label>
                    – Browse from a wide range of partner restaurants based on
                    location, cuisine, or user ratings.
                  </label>
                </li>
                <li>
                  <span>Reserve in Seconds</span>
                  <label>
                    – Select your preferred restaurant, pick a time, and confirm
                    your reservation instantly.
                  </label>
                </li>
                <li>
                  <span>Dine & Enjoy</span>
                  <label>
                    – Arrive at the restaurant, enjoy your meal, and experience
                    seamless service.
                  </label>
                </li>
                <li>
                  <span>Share Your Experience</span>
                  <label>
                    – Help fellow diners by leaving feedback and ratings.
                  </label>
                </li>
              </ol>
            </div>
            <div className="howItWorkDiv">
              <h3>Say No to Cold & Stale Food!</h3>
              <p>
                Why eat long-traveled, cold food when you can enjoy a fresh, hot
                meal straight from the kitchen? With Dfoodo, you experience
                authentic dining, served fresh on your table, just the way it’s
                meant to be!
              </p>
            </div>
            <div className="howItWorkDiv">
              <h3>More Than Just Dining</h3>
              <p>
                Dfoodo isn’t just about booking a table—it’s about enhancing the
                entire dining journey. We bring together food lovers,
                restaurants, and technology to create a smarter way to dine out.
                From exclusive members-only benefits to AI-powered insights that
                help you pick the perfect restaurant, we’re here to make every
                meal an experience to remember.
              </p>
            </div>
            <div className="howItWorkDiv">
              <h3>Join the Dfoodo Family Today!</h3>
              <p>
                With Dfoodo, dining out is no longer just about food—it’s about
                experiences, connections, and moments worth savoring. Join
                thousands of food lovers who trust Dfoodo to make their dining
                adventures simpler, smarter, and more exciting.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="list_your_venue_aboutus">
        <DownloadApp />
      </section>
      <section>
        <AreYouAVenueOwner />
      </section>
      <Mobilefotter />
      <Footer />
    </>
  );
};

export default AboutUs;

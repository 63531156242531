import React from "react";
import { Link } from "react-router-dom";
const AreYouAVenueOwner = () => {
  return (
    <section className="areyoua_venue_owner_section">
      <div className="container">
        <div className="row m-0">
          <div className="are_you_a_venue_owner col-lg-8 m-auto">
            {/* <h2>Are You a Restaurant Owner?</h2> */}
            <h2>Partner with Us – More Diners, Zero Commission! </h2>

            {/* <h6>Register your Restaurant</h6> */}
            <p>
              With our easy self-onboarding process, you can get started in
              minutes. Share your details, and we’ll help you unlock new
              opportunities for your business!
            </p>
            <Link to="/registerMyVenue">Join Dfoodo Today</Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AreYouAVenueOwner;

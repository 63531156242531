import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./Css/PatnerWithUs.css";
import custmrimg from "../Assets/custmrimg.avif";
import doorStepImg from "../Assets/doorStepImg.avif";
import HelpSupprtImg from "../Assets/HelpSupprtImg.avif";
import { FiCheckCircle } from "react-icons/fi";
import upArrow from "../Assets/downArrowBlack.svg";
import Testimonial from "./Testimonial";
import DOMPurify from "dompurify";
import successPaymentGif from "../Assets/successPaymentGif.gif";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import whyshouldimg1 from "../Assets/whyshouldimg1.png";
import whyshouldimg2 from "../Assets/whyshouldimg2.png";
import whyshouldimg3 from "../Assets/whyshouldimg3.png";
import whyshouldimg4 from "../Assets/whyshouldimg4.png";
import whyshouldimg5 from "../Assets/whyshouldimg5.png";
import {
  server_post_data,
  get_all_faq_website,
  save_restaurantOwnerdetails,
} from "../ServiceConnection/serviceconnection.js";
import {
  check_vaild_save,
  combiled_form_data,
  empty_form,
  handleError,
  handleIaphabetnumberChange,
  handleNumbersChange,
  handleURLChange,
  handleLinkClick,
  handleAphabetsChange,
  handleEmailChange,
} from "../CommonJquery/CommonJquery.js";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
export default function PatnerWithUs() {
  const [getFaq, SetFaq] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };
  const master_data_get = async () => {
    const fd = new FormData();

    await server_post_data(get_all_faq_website, fd)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          SetFaq(Response.data.message.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);
  const cardData = [
    {
      imgSrc: whyshouldimg1,
      title: "Freedom to grow with zero commissions",
      description:
        "No commission deductions—ever! With Dfoodo's flat annual subscription, your profits are truly yours to keep.",
    },
    {
      imgSrc: whyshouldimg2,
      title: "Attract morecustomers with customizable offers",
      description:
        "Easily create and manage exclusive discounts for up to 50% off to attract diners during off-peak hours or special occasions. Boost your footfall with smart, automated happy hour promotions!",
    },
    {
      imgSrc: whyshouldimg3,
      title: "Real-time reservation management",
      description:
        "Manage your restaurant effortlessly with Dfoodo’s partner dashboard and mobile app. Track reservations and customer insights anytime, anywhere.",
    },
    {
      imgSrc: whyshouldimg4,
      title: "Freedom from delivery complexities",
      description:
        "Focus on dine-in excellence without worrying about delivery logistics. Delight your guests with the best dining experience.",
    },
    {
      imgSrc: whyshouldimg5,
      title: "24/7 Partner Support",
      description:
        "Our dedicated team is always on hand to provide operational assistance and help you grow your business.",
    },
  ];
  const [charCount, setCharCount] = useState(0);

  // Handle input change
  const handleInputChange = (e) => {
    const value = e.target.value;
    setCharCount(value.length); // Update the character count
  };

  //form Submit
  const handleSaveChangesdynamic = async (form_data, url_link) => {
    let vaild_data = check_vaild_save(form_data);
    // seterror_show("");
    if (!$("#availability").prop("checked")) {
      vaild_data = false;
    }
    if (vaild_data) {
      let fd_from = combiled_form_data(form_data, null);
      setShowLoader(true);
      await server_post_data(url_link, fd_from)
        .then((Response) => {
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            setShowModal(true);
            setTimeout(() => {
              setShowModal(false);
            }, 5000); // 3000ms = 3 seconds
            empty_form(form_data);
          }
          setShowLoader(false);
        })
        .catch((error) => {
          setShowLoader(false);
        });
    }
  };
  return (
    <div>
      <Header />
      <div className="PatnerHeroSection">
        <div className="col-md-10 ">
          <div className="d-flex justify-content-center flex-column justify-content-center align-items-center">
            {" "}
            <h1>Partner with Dfoodo and Transform Your Restaurant Business</h1>
            <p className="patnerTg2"> Freedom from Commissions!</p>
            <p>
              Dfoodo is the only platform offering complete freedom from hefty
              commission fees. With our flat annual subscription of just
              ₹10,000, you enjoy transparent pricing and predictable growth
              without hidden charges.
            </p>
            <div className="registerRestBtn">
              {" "}
              <button
                type="button"
                onClick={() => handleLinkClick("/registerMyVenue")}
              >
                Register your restaurant
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="getSTartDiv">
        {" "}
        <div className="col-md-9">
          <div className="getstartData">
            {" "}
            <h2>Get Started in Just 10 Minutes</h2>{" "}
            <p>
              Join Dfoodo and take control of your restaurant's success. All you
              need to register:
            </p>
            <div className="row mt-2">
              <div className="col-sm-6 col-md-4 mb-4">
                <div className="d-flex gap-2  align-items-center">
                  <FiCheckCircle style={{ color: "green", fontSize: "18px" }} />
                  <span>PAN card</span>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 mb-4">
                <div className="d-flex gap-2 align-items-center ">
                  <FiCheckCircle style={{ color: "green", fontSize: "18px" }} />
                  <span>Menu details (including one dish image)</span>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 mb-4">
                <div className="d-flex gap-2  align-items-center">
                  <FiCheckCircle style={{ color: "green", fontSize: "18px" }} />
                  <span>Bank account details</span>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="d-flex gap-2 ">
                  <FiCheckCircle
                    style={{ color: "green", fontSize: "18px" }}
                    className="marginClss"
                  />
                  <div className="gstAskDiv">
                    {" "}
                    <span>GST number (if applicable)</span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4">
                <div className="d-flex gap-2">
                  <FiCheckCircle
                    style={{ color: "green", fontSize: "18px" }}
                    className="marginClss"
                  />
                  <div className="gstAskDiv">
                    {" "}
                    <span>FSSAI license</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/**---------------WHy should  You pathner Section------------ */}{" "}
      <div className="WHyShouldSection">
        <div className="col-md-9">
          <div className="whyShouldHeading">
            <div className="lin1"></div>
            <div className="lin2 HeadingData">
              <h2>Why should you partner with Dfoodo?</h2>
            </div>
            <div className="lin3"></div>
          </div>

          <div className="row shouldCardDiv">
            {cardData.map((card, index) => (
              <div key={index} className="col-md-4 col-sm-6">
                <div className="ShouldCardData">
                  <img src={card.imgSrc} alt={card.title} />
                  <h3 style={{ textAlign: "center" }}> {card.title}</h3>
                  <p>{card.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/**------------------testimoniaL----------------------- */}
      <Testimonial />
      {/**-------------------------Faq ----------------------- */}
      <section className="faq_section mt-5">
        <div className="container">
          <div className="faq_section_container">
            <div className="accordion_container w-100">
              <div className="faq_section_container_heading">
                <h2 className="text-align-center">FAQS</h2>
              </div>
              <div className="accordion">
                {getFaq.map((item, index) => (
                  <div key={index} className="accordion-item bgColorr">
                    <div
                      className={`accordion-title ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={() => handleClick(index)}
                    >
                      {item.question_name}
                      <span className="dropdown-icon">
                        <img
                          src={upArrow}
                          alt="toggle arrow"
                          className={`arrow ${
                            index === activeIndex ? "up" : ""
                          }`}
                        />
                      </span>
                    </div>
                    {index === activeIndex && (
                      <div className="accordion-content">
                        <p
                          className="accordion-content-text"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(item.answer_name),
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*--------------Get Help Form--------------- */}
      <section className="patnerGetHelp" id="getHlp">
        <div className="container-lg">
          <div className="row rowReverse">
            <div className="col-md-7">
              <div className="contrPatnerForm">
                <form
                  className="venue-registration-form"
                  id="vanueregistration"
                >
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <label htmlFor="venueName">
                        First Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        id="Owner_fname"
                        name="Owner_fname"
                        minLength={3}
                        maxLength={70}
                        onInput={handleAphabetsChange}
                        className="form-control trio_mandatory"
                        placeholder="First Name "
                      />
                    </div>
                    <div className="col-md-6 mb-2">
                      <label htmlFor="venueLocation d-flex flex-row">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="Owner_lname"
                        name="Owner_lname"
                        minLength={3}
                        maxLength={70}
                        onInput={handleAphabetsChange}
                        className="form-control"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 mb-2">
                      <label htmlFor="contactPerson">
                        Your Email<span>*</span>
                      </label>
                      <input
                        type="text"
                        id="Email"
                        name="Email"
                        minLength={3}
                        maxLength={70}
                        onInput={handleEmailChange}
                        className="form-control trio_mandatory"
                        placeholder="Enter Your Email"
                      />
                    </div>
                    <div className="col-md-6 mb-2">
                      <label htmlFor="contactEmail">
                        Restaurant Name<span>*</span>
                      </label>
                      <input
                        type="text"
                        id="restaurant_name"
                        name="restaurant_name"
                        minLength={3}
                        maxLength={70}
                        onInput={handleIaphabetnumberChange}
                        className="form-control trio_mandatory"
                        placeholder="Enter Restaurant Name"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12 mb-2">
                      <label htmlFor="phone">Restaurant Website</label>
                      <input
                        type="email"
                        id="restaurant_website"
                        name="restaurant_website"
                        minLength={3}
                        maxLength={70}
                        onInput={handleURLChange}
                        className="form-control"
                        placeholder="Enter Restaurant Website"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label htmlFor="restaurant_address">
                        Message<span>*</span>
                      </label>

                      <textarea
                        id="restaurant_address"
                        name="restaurant_address"
                        minLength={3}
                        maxLength={300}
                        onInput={handleInputChange}
                        className="form-control trio_mandatory PatnerResAddrs"
                        placeholder="Message"
                        rows="4"
                      ></textarea>
                    </div>
                  </div>
                  <div className="containerOfcheckBox">
                    <div className="checkBox_registerMyVenue">
                      {" "}
                      <input
                        type="checkbox"
                        id="availability"
                        name="availability"
                      />
                      <span className="check_box_text">
                        <p>
                          I do not wish to receive communications via email/SMS
                          from DFOODO
                        </p>
                      </span>
                    </div>
                  </div>
                </form>
                <div className="formSubmtBtnPatnr">
                  <button
                    type="button"
                    onClick={() =>
                      handleSaveChangesdynamic(
                        "vanueregistration",
                        save_restaurantOwnerdetails
                      )
                    }
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="nedHlpContnt">
                {" "}
                <div>
                  {" "}
                  <h2>Need Assistance?</h2>
                  <p>
                    We're here to help! Whether you have questions about our
                    services or need assistance with something specific, feel
                    free to reach out. Our team is ready to support you every
                    step of the way.
                  </p>
                </div>
                <div className="contUssContnt">
                  <p style={{ marginTop: "1.5rem" }}>
                    <strong>Email us at:</strong>
                  </p>
                  <p>
                    <AiOutlineMail
                      style={{
                        marginRight: "10px",
                        color: "var(--primary-color)",
                      }}
                    />
                    <a href="mailto:support@dfoodo.com">support@dfoodo.com</a>
                  </p>

                  <p style={{ marginTop: "1.5rem" }}>
                    <strong>Call us at:</strong>
                  </p>
                  <p>
                    <AiOutlinePhone
                      style={{ marginRight: "10px", color: "#28a745" }}
                    />
                    <a href="tel:+07554902894" style={{ color: "#28a745" }}>
                      0755 490 2894
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="text-center submtformmdl"
        centered
      >
        <Modal.Body className="bg-gray-100 p-6">
          <div className="succSSimg">
            <img src={successPaymentGif} alt="successPaymentGif" />
          </div>
          <h4 className="succMsg">Success</h4>

          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold Scndhead ">
              Your form has been successfully submitted to DFOODO!
            </p>
            <p className="text-gray-600 thirdhead">
              Our team will get back to you shortly.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

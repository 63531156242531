import { useEffect } from "react";
import React from "react";
import successPaymentGif from "../Assets/successPaymentGif.gif";
import { useLocation } from "react-router-dom";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_chargebee_subscription,
} from "../ServiceConnection/serviceconnection";
import { retrieveData } from "../LocalConnection/LocalConnection";
let default_restaurant_id_staff = 0;
const AlReadySubmitForm = () => {
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const locationdata = useLocation();
  const searchParams = new URLSearchParams(locationdata.search);
  const subId = searchParams.get("sub_id"); // Extracts 'sub_id'
  const start_date = searchParams.get("start_date"); // Extracts 'sub_id'
  const cus_id = searchParams.get("cus_id"); // Extracts 'sub_id's
  const state = searchParams.get("state"); // Extracts 'sub_id's
  // useEffect(() => {
  //   if (
  //     subId !== null &&
  //     subId !== undefined &&
  //     subId !== "" &&
  //     subId !== "0" &&
  //     subId !== 0
  //   ) {
  //     if (state === "succeeded") {
  //       saveSubsData(subId, cus_id);
  //       // console.log(subId);
  //       // console.log(cus_id);
  //     }
  //   } else {
  //     // master_data_get();
  //     alert("transaction failed");
  //     console.log("Go Normal Way");
  //   }
  // }, []);

  const saveSubsData = async (sub, customer_id) => {
    // setshowLoader(true);
    const form_data = new FormData();
    console.log('customer_id',customer_id)
    form_data.append("restaurant_id", default_restaurant_id_staff);
    form_data.append("customer_id_dd", customer_id.trim());
    const currentDate = new Date().toISOString().split("T")[0];
    form_data.append("subscription_start_date", currentDate);
    form_data.append("transaction_id", sub);

    await server_post_data(save_chargebee_subscription, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          // nextStep();
          alert(Response.data.message)
          console.log(Response.data.message);
        }
        // setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        // setshowLoader(false);
      });
  };

  return (
    <div className="container-lg">
      <div className="AllreadiSubmit ">
        {" "}
        <div className="col-md-6">
          {" "}
          <div
            className="contFOrm text-center"
            style={{ boxShadow: "0px 4px 18px 0px #00000040" }}
          >
            <div className="succSSimg2">
              <img src={successPaymentGif} alt="successPaymentGif" />
            </div>

            <div className="flex flex-col items-center">
              <p className="text-lg font-semibold Scndhead">
                You have already submitted the form
              </p>
              <p className="text-gray-600 thirdhead">
                Our team will connect with you soon
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlReadySubmitForm;

import React, { useState, useEffect, useRef } from "react";
import "./Css/DetailedVenue.css";
import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import right from "../Assets/right_arrow.svg";
import $ from "jquery";
import Mobilefotter from "./Mobilefotter.js";
import Reviews from "./Reviews";
import Footer from "./Footer";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import leftArrowIcon from "../Assets/leftArrowIcon.svg";
import rightArrowIcon from "../Assets/rightArrowIcon.svg";
import personCalendar from "../Assets/personSvg_detailedVenue.svg";
import personCalendarB from "../Assets/personB.svg";
import calendarfrom from "../Assets/calendar_detailedVenue.svg";
import preview from "../Assets/preview.svg";
import previewB from "../Assets/previewB.svg";
import rightArrow from "../Assets/rightArrow.svg";
import locationsvg from "../Assets/locationSvg.svg";
import redStar from "../Assets/StarRating.svg";
import timerClock from "../Assets/watchB.svg";
import timerClockW from "../Assets/watchW.svg";
import quesMark from "../Assets/questionMark.svg";
import avgpriceIcon from "../Assets/averagePriceDetailedVenue.svg";
import rightArrowWhite from "../Assets/rightArrow_white.svg";
import rightArrowB from "../Assets/arrowB.svg";
import AddBtn from "../Assets/addNewInput.svg";
import crossIcon from "../Assets/cross.svg";
import crossCalander from "../Assets/crossCalander.svg";
import { Modal } from "react-bootstrap";
import Successs from "../Assets/checkone.webp";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import successPaymentGif from "../Assets/successPaymentGif.gif";
import {
  server_post_data,
  get_restropage_webapp,
  get_all_timing_date_wise,
  create_table_reservation_website,
  APL_LINK,
  get_restropage_webapp_details,
} from "../ServiceConnection/serviceconnection.js";
import {
  formatTimeintotwodigit,
  handleIaphabetnumberChange,
  handleSuccess,
  handleError,
  formatDateStringdot,
} from "../CommonJquery/CommonJquery.js";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
let customer_id = "0";
let customer_name = "";
let customer_mobile_no = "";
let customer_email = "";
const DetailedVenue = () => {
  customer_id = retrieveData("customer_id");
  customer_name = retrieveData("customer_name");
  customer_mobile_no = retrieveData("customer_mobile_no");
  customer_email = retrieveData("customer_email");
  const location = useLocation();
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const currentUrl = location.pathname.substring(1);
  const [detail, setDetail] = useState([]);
  const [restoAbout, setrestoAbout] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [like_data, setlike_data] = useState([]);
  const [activeTab, setActiveTab] = useState("about");
  const [data, setData] = useState([]);
  const [TimingData, setTimingData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(0);

  const [selectedChild, setselectedChild] = useState(0);
  const [selectedpet, setselectedpet] = useState(0);
  const [ImageLink, setImageLink] = useState("");
  const [showModalKitchen, setShowModalKitchen] = useState(false);
  const [showModalKitchenMsg, setShowModalKitchenMsg] = useState("");
  const [showmsgforbook, setshowmsgforbook] = useState("");
  const [TimeData, setTimeData] = useState([]);
  const [errorform, seterrorform] = useState({ error: false });
  const [errormsg, seterrormsg] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [totalbookingtoday, settotalbookingtoday] = useState(0);
  const [selectedrtsd_idd, setSelectedrtsd_idd] = useState(false);
  const [showAddbutton, setshowAddbutton] = useState(true);
  const [showAddbutton2, setshowAddbutton2] = useState(true);
  const [showAddbutton3, setshowAddbutton3] = useState(true);
  const storedate =
    retrieveData("storedate") === "0" ? new Date() : retrieveData("storedate");
  const storeperson =
    retrieveData("storeperson") === "0" ? "1" : retrieveData("storeperson");
  const [selectedGuest, setSelectedGuest] = useState(parseInt(storeperson, 10));

  //success modal
  const [showModal, setShowModal] = useState(false);

  const handleFetchData = async (date_for_book) => {
    setShowLoader(true);
    setData(null);
    var form_data = new FormData();
    form_data.append("special_date", date_for_book);
    form_data.append("reservation_id", "0");
    form_data.append("default_restaurant_id", detail.primary_id);
    await server_post_data(
      get_all_timing_date_wise,
      form_data,
      detail.database_name
    )
      .then((Response) => {
        setTimeData(Response.data.message.data_timedata);
        setShowModalKitchenMsg(Response.data.message.kichan_lose_msg);
        setShowLoader(false);
        let online_booking_status = 0;
        let start_stop_status = 0;
        if (Response.data.message.get_date_off_date.length > 0) {
          online_booking_status =
            Response.data.message.get_date_off_date[0].online_booking_status;
          start_stop_status =
            Response.data.message.get_date_off_date[0].start_stop_status;
        } else {
          if (Response.data.message.get_date_off_on_day.length > 0) {
            online_booking_status =
              Response.data.message.get_date_off_on_day[0]
                .online_booking_status;
            start_stop_status =
              Response.data.message.get_date_off_on_day[0].start_stop_status;
          }
        }
        if (
          Response.data.message.data_timedatadetails.length > 0 &&
          online_booking_status === 0 &&
          start_stop_status === 0
        ) {
          setData(Response.data.message.data_timedatadetails);
          seterrorform({ error: true });
        } else {
          seterrormsg(Response.data.message.msg_for_no_show);
          seterrorform({ error: false });
        }
        setSelectedTime(null);
        setSelectedGuest(1); // Set the selected guest count
        setCurrentStep(2);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };
  const handleclickstep = (step_click, data_for_update) => {
    if (customer_id !== "0") {
      if (step_click === 0) {
        setSelectedDate(null);
        setSelectedTime(null);
        setSelectedGuest(1); // Set the selected guest count
        setCurrentStep(1);
      } else if (step_click === 1) {
        let month = data_for_update.$M + 1;
        let day = data_for_update.$D;
        let year = data_for_update.$y;

        if (month < 10) {
          month = "0" + month;
        }
        if (day < 10) {
          day = "0" + day;
        }
        let full_date = year + "-" + month + "-" + day;

        setSelectedDate(full_date);

        handleFetchData(full_date);
      } else if (step_click === 2) {
        let make_data = data_for_update.split("~@~");
        setSelectedrtsd_idd(make_data[0]);
        setSelectedTime(make_data[1]);
        setSelectedDiscount(make_data[2]);
        setSelectedGuest(1); // Set the selected guest count
        let click_by_popup = 0;
        TimeData.map((item) => {
          if (make_data[1] >= item.last_arrival_time) {
            click_by_popup = 1;
            setShowModalKitchen(true);
          }
        });
        if (click_by_popup === 0) {
          setCurrentStep(3);
        }
      } else if (step_click === 3) {
        setSelectedGuest(data_for_update); // Set the selected guest count
        setCurrentStep(4);
      } else if (step_click === 4) {
      }
    } else {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  };
  const handleclickbackstep = (step_click, data_for_update) => {
    if (currentStep === 1 && !selectedDate) {
      alert("Please select a date before proceeding to the next step.");
      return;
    }

    if (step_click === 0) {
      setSelectedDate(null);
      setSelectedTime(null);
      setSelectedGuest(1);
      setCurrentStep(1);
    } else if (step_click === 1) {
      if (!selectedTime) {
        alert("Please select a time before proceeding.");
        return;
      }
      setSelectedTime(data_for_update);
      setSelectedGuest(1);
      setCurrentStep(2);
    } else if (step_click === 2) {
      setSelectedGuest(data_for_update);
      setCurrentStep(3);
    } else if (step_click === 3) {
      setSelectedGuest(data_for_update);
      setCurrentStep(4);
    } else if (step_click === 4) {
    }
  };

  const [isReadMore, setIsReadMore] = useState(true);
  console.log(restoAbout);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const words = typeof restoAbout === "string" ? restoAbout.split(" ") : [];

  // Show only the first 15 words if isReadMore is true
  const textToShow = isReadMore
    ? words.slice(0, 10).join(" ") + (words.length > 10 ? "..." : "")
    : restoAbout;

  //readmore section:
  function ReadMore() {
    return (
      <div className="read-more-section ">
        <p>
          {textToShow}
          {words.length > 10 && (
            <span
              className="moreTextLessText"
              onClick={toggleReadMore}
              style={{ cursor: "pointer", marginLeft: "5px" }}
            >
              {isReadMore ? " Read More" : " Read Less"}
            </span>
          )}
        </p>
      </div>
    );
  }
  const value_date = dayjs(new Date(storedate));

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("current_url", "/" + currentUrl);
    fd.append("customer_id", customer_id);

    try {
      const Response = await server_post_data(get_restropage_webapp, fd);

      if (Response.data.error) {
        console.error("Error:", Response.data.message);
      } else {
        if (
          Array.isArray(Response.data.message.restro) &&
          Response.data.message.restro.length > 0
        ) {
          const restroData = Response.data.message.restro[0];

          master_data_get_addd(restroData.database_name);
        } else {
          console.warn("Restro data is empty or missing");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setshowLoaderAdmin(false);
    }
  };
  const master_data_get_addd = async (database_name) => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("current_url", "/" + currentUrl);
    fd.append("customer_id", customer_id);

    try {
      const Response = await server_post_data(
        get_restropage_webapp_details,
        fd,
        database_name
      );

      if (Response.data.error) {
        console.error("Error:", Response.data.message);
      } else {
        if (
          Array.isArray(Response.data.message.restro) &&
          Response.data.message.restro.length > 0
        ) {
          const restroData = Response.data.message.restro[0];

          setDetail(restroData);
          setrestoAbout(restroData.restaurant_about || "");

          setlike_data(Response.data.message.like_data);
          setImageLink(Response.data.message.image_link);
          settotalbookingtoday(Response.data.message.total_booking_count);
          setTimingData(restroData.days_data);

          // Construct breadcrumbs with additional checks
          const newBreadcrumbs = [
            { name: "Home", path: "/" },
            {
              name: restroData.restaurant_country || "Country",
              path: "/",
            },
            {
              name: restroData.restaurant_city || "City",
              path: "/",
            },
            {
              name: restroData.restaurant_name || "Restaurant",
            },
          ];

          setBreadcrumbs(newBreadcrumbs);
        } else {
          console.warn("Restro data is empty or missing");
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setshowLoaderAdmin(false);
    }
  };
  useEffect(() => {
    master_data_get();
  }, []);

  let child_length = 3;
  let pet_length = 3;
  let guest_length = 3;
  const [addCustomChild, setAddCustomChild] = useState(false);
  const [addCustomPet, setAddCustomPet] = useState(false);
  const [addCustomGuest, setAddCustomGuest] = useState(false);
  const SelectedChangeChild = (child_name, click_type) => {
    setshowAddbutton(true);
    if (click_type === "1") {
      setselectedChild(child_name);
      setAddCustomChild(false);
      setshowAddbutton2(true);
    } else {
      child_name.target.value = child_name.target.value.replace(/[^0-9]/g, "");
      if (child_name.target.value === "") {
        child_name.target.value = 1;
      } else if (Number(child_name.target.value) < 1) {
        child_name.target.value = 1;
      }
      setselectedChild(child_name.target.value);
      setshowAddbutton2(true);
    }
  };
  const addCustomChildInput = () => {
    setAddCustomChild(true);
    setselectedChild("");
    setshowAddbutton2(false);
  };

  const SelectedChangePet = (pet_name, click_type) => {
    setshowAddbutton(true);
    if (click_type === "1") {
      setshowAddbutton3(true);
      setselectedpet(pet_name);
      setAddCustomPet(false);
    } else {
      pet_name.target.value = pet_name.target.value.replace(/[^0-9]/g, "");
      if (pet_name.target.value === "") {
        pet_name.target.value = 1;
      } else if (Number(pet_name.target.value) < 1) {
        pet_name.target.value = 1;
      }
      setselectedpet(pet_name.target.value);
      setshowAddbutton3(true);
    }
  };
  const addCustomPetInput = () => {
    setAddCustomPet(true);
    setselectedpet("");
    setshowAddbutton3(false);
  };
  const SelectedChangeGuest = (pet_name, click_type) => {
    setshowAddbutton(true);
    if (click_type === "1") {
      setSelectedGuest(pet_name);
      setAddCustomGuest(false);
      setshowAddbutton(true);
    } else {
      pet_name.target.value = pet_name.target.value.replace(/[^0-9]/g, "");
      if (pet_name.target.value === "") {
        pet_name.target.value = 1;
      } else if (Number(pet_name.target.value) < 1) {
        pet_name.target.value = 1;
      }
      setSelectedGuest(pet_name.target.value);
      setshowAddbutton(true);
    }
  };
  const addCustomGuestInput = () => {
    setAddCustomGuest(true);
    setSelectedGuest("");
    setshowAddbutton(false);
  };
  const function_save = () => {
    if ($("#checkSurfaceEnvironment-1").prop("checked") === true) {
      //do something
      sava_booking_data();
    } else {
      alert(
        "Please read and agree to the terms and conditions before proceeding."
      );
    }
  };
  let call_time = 0;
  const sava_booking_data = async () => {
    setShowLoader(true);
    if (call_time === 0) {
      call_time = 1;

      var fd_from = new FormData();

      fd_from.append("reservation_id", "0");
      fd_from.append("guest_mobile_no", customer_mobile_no);
      fd_from.append("customer_id", customer_id);
      fd_from.append("book_date", selectedDate);
      fd_from.append("operational_time_detail_id", selectedrtsd_idd);
      fd_from.append("operational_booking_time", selectedTime);
      fd_from.append("booking_type", "2");
      fd_from.append("realtime", "0");
      if (selectedChild === "") {
        fd_from.append("no_of_child", 0);
      } else {
        fd_from.append("no_of_child", selectedChild);
      }
      if (selectedpet === "") {
        fd_from.append("no_of_pets", 0);
      } else {
        fd_from.append("no_of_pets", selectedpet);
      }
      fd_from.append("no_of_guest", selectedGuest);

      fd_from.append("total_tablebooking", "0");
      fd_from.append("dining_area_id", "0");
      fd_from.append("reservation_comment", $("#order_comment").val());
      fd_from.append("guest_name", customer_name);
      fd_from.append("guest_email", customer_email);
      fd_from.append("default_restaurant_id", detail.primary_id);

      await server_post_data(
        create_table_reservation_website,
        fd_from,
        detail.database_name
      )
        .then((Response) => {
          setShowLoader(false);
          if (Response.data.error) {
            handleError(Response.data.message);
          } else {
            setshowmsgforbook(Response.data.message);
            setCurrentStep(5);
            setShowModal(true);
            setTimeout(() => {
              setShowModal(false);
            }, 3000); // 3000ms = 3 seconds
          }
          call_time = 0;
        })
        .catch((error) => {
          console.log(error);
          setShowLoader(false);
          call_time = 0;
        });
    }
  };
  const [isModalVisible, setIsModalVisible] = useState(false);
  const greyBackgroundClass = currentStep === 4 ? "greyBackground" : "";
  const [isModalOpen, setISModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalVisible(!isModalVisible);
    setISModalOpen(true);
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setISModalOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);
  const [isFooterVisible, setIsFooterVisible] = useState(false);
  const footerRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsFooterVisible(entry.isIntersecting);
      },
      {
        root: null,
        threshold: 0.6, // 10% of the footer must be visible to trigger the callback
      }
    );

    if (footerRef.current) {
      observer.observe(footerRef.current);
    }

    return () => {
      if (footerRef.current) {
        observer.unobserve(footerRef.current);
      }
    };
  }, []);

  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images =
    detail.menuimages &&
    detail.menuimages.map(
      (menu_img) => APL_LINK + ImageLink + menu_img.image_name
    );
  return (
    <>
      <div className="detailed_venue_wrapper">
        <Header />
        <section>
          <div className="container-lg mt-3 mb-1">
            <div className="venuePage_venueCategory_heading">
              {breadcrumbs.map((crumb, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <img src={rightArrow} alt="rightArrow" />}

                  {index === breadcrumbs.length - 1 ? (
                    <span>{crumb.name}</span>
                  ) : (
                    <Link to={crumb.path}>{crumb.name}</Link>
                  )}
                </React.Fragment>
              ))}
            </div>
          </div>
        </section>
        {/* images gallery section */}
        <section>
          <section className="image_gallery_section d-sm-none">
            <div className="row m-0">
              <div className="wrapper_carousel p0a576">
                <Carousel
                  className="fade-img"
                  showIndicators={false}
                  showStatus={false}
                  showThumbs={false}
                  renderArrowPrev={(clickHandler, hasPrev) =>
                    hasPrev && (
                      <button
                        type="button"
                        onClick={clickHandler}
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "15px",
                          transform: "translateY(-50%)",
                          zIndex: 2,
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                        }}
                      >
                        <img
                          src={leftArrowIcon}
                          alt="Previous"
                          style={{ width: "42px", height: "42px" }}
                        />
                      </button>
                    )
                  }
                  renderArrowNext={(clickHandler, hasNext) =>
                    hasNext && (
                      <button
                        type="button"
                        onClick={clickHandler}
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "15px",
                          transform: "translateY(-50%)",
                          zIndex: 2,
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          outline: "none",
                        }}
                      >
                        <img
                          src={rightArrowIcon}
                          alt="Next"
                          style={{ width: "42px", height: "42px" }}
                        />
                      </button>
                    )
                  }
                >
                  {detail.images &&
                    detail.images.length > 0 &&
                    detail.images.map((image, index) => (
                      <div key={index}>
                        <img
                          src={APL_LINK + ImageLink + image.image_name}
                          alt={`img${index}`}
                        />
                      </div>
                    ))}
                  <div></div>
                </Carousel>
                <div className="black_section_carousel">
                  <span className="first_row_black_section_carousel">
                    <div>
                      <h6>{detail.restaurant_name}</h6>
                      <p>{detail.restaurant_full_adrress}</p>
                    </div>
                    <div className="first_row_black_section_carousel hidden">
                      <p>1.16 km</p>
                      <img src={locationsvg} alt="location" />
                    </div>
                  </span>
                  <span className="first_row_black_section_carousel align-items-center">
                    <div className="french_text">
                      <h6>
                        {detail.cuisie &&
                          detail.cuisie.slice(0, 3).map((cuisine, index) => (
                            <span key={index}>
                              {cuisine.cuisine_name}
                              {index < 2 &&
                                index < detail.cuisie.length - 1 &&
                                ", "}
                            </span>
                          ))}
                      </h6>
                    </div>
                    <div className="first_row_black_section_carousel align-items-center">
                      <span className="d-flex align-items-center reviews_black_section">
                        <img
                          style={{ marginTop: "-3px" }}
                          src={redStar}
                          alt="redStar"
                        />
                        <p className="m-0">{detail.rating}</p>
                      </span>
                      <span>
                        <p className="m-0">
                          {detail.review && detail.total_reviews} reviews
                        </p>
                      </span>
                    </div>
                  </span>
                  <hr />
                  <span className="last_line_black_section mb-4">
                    <div className="first_row_black_section_carousel">
                      <img src={timerClock} alt="timerClock" />
                      <p style={{ marginBottom: "0" }}>
                        Open from{" "}
                        {formatTimeintotwodigit(detail.breakfast_starttime)} -{" "}
                        {formatTimeintotwodigit(detail.dinner_endtime)}
                      </p>
                    </div>
                    <div className="first_row_black_section_carousel">
                      <img src={avgpriceIcon} alt="avgpriceIcon" />
                      <p style={{ marginBottom: "0" }}>
                        Average price {detail.first_money_icon}{" "}
                        {detail.restaurant_price} {detail.last_money_icon}
                      </p>
                      <img src={quesMark} alt="quesMark" />
                    </div>
                  </span>
                </div>
              </div>
            </div>
          </section>
          <div className="container-lg p0a576">
            <div className="row m-0">
              <div className="col-lg-8 m-0 p-0 ">
                <section className="image_gallery_section d-sm-block d-none">
                  <div className="row m-0">
                    <div className="wrapper_carousel">
                      <Carousel
                        className="fade-img"
                        showIndicators={false}
                        showStatus={false}
                        showThumbs={false}
                        renderArrowPrev={(clickHandler, hasPrev) =>
                          hasPrev && (
                            <button
                              type="button"
                              onClick={clickHandler}
                              style={{
                                position: "absolute",
                                top: "60%",
                                left: "15px",
                                transform: "translateY(-50%)",
                                zIndex: 2,
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                outline: "none",
                              }}
                            >
                              <img
                                src={leftArrowIcon}
                                alt="Previous"
                                style={{ width: "42px", height: "42px" }}
                              />
                            </button>
                          )
                        }
                        renderArrowNext={(clickHandler, hasNext) =>
                          hasNext && (
                            <button
                              type="button"
                              onClick={clickHandler}
                              style={{
                                position: "absolute",
                                top: "60%",
                                right: "15px",
                                transform: "translateY(-50%)",
                                zIndex: 2,
                                background: "none",
                                border: "none",
                                cursor: "pointer",
                                outline: "none",
                              }}
                            >
                              <img
                                src={rightArrowIcon}
                                alt="Next"
                                style={{ width: "42px", height: "42px" }}
                              />
                            </button>
                          )
                        }
                      >
                        {detail.images &&
                          detail.images.length > 0 &&
                          detail.images.map((image, index) => (
                            <div key={index}>
                              <img
                                src={APL_LINK + ImageLink + image.image_name}
                                alt={`img${index}`}
                              />
                            </div>
                          ))}
                        <div></div>
                      </Carousel>
                      <div className="black_section_carousel">
                        <span className="first_row_black_section_carousel">
                          <div>
                            <h6>{detail.restaurant_name}</h6>
                            <p>{detail.restaurant_full_adrress}</p>
                          </div>
                          <div className="first_row_black_section_carousel hidden">
                            <p>1.16 km</p>
                            <img src={locationsvg} alt="location" />
                          </div>
                        </span>
                        <span className="first_row_black_section_carousel align-items-center">
                          <div className="french_text">
                            <h6>
                              {detail.cuisie &&
                                detail.cuisie.length > 0 &&
                                detail.cuisie[0].cuisine_name}
                            </h6>
                          </div>
                          <div className="first_row_black_section_carousel align-items-center">
                            <span className="d-flex reviews_black_section">
                              <img src={redStar} alt="redStar" />
                              <p className="m-0">{detail.rating}</p>
                            </span>
                            <span>
                              <p className="m-0">
                                {detail.review && detail.total_reviews} reviews
                              </p>
                            </span>
                          </div>
                        </span>
                        <hr />
                        <span className="last_line_black_section mb-4">
                          <div className="first_row_black_section_carousel">
                            <img src={timerClock} alt="timerClock" />
                            <p style={{ marginBottom: "0" }}>
                              Open from{" "}
                              {formatTimeintotwodigit(
                                detail.breakfast_starttime
                              )}{" "}
                              -{" "}
                              {formatTimeintotwodigit(detail.breakfast_endtime)}
                            </p>
                          </div>
                          <div className="first_row_black_section_carousel">
                            <img src={avgpriceIcon} alt="avgpriceIcon" />
                            <p style={{ marginBottom: "0" }}>
                              Average price {detail.first_money_icon}{" "}
                              {detail.restaurant_price} {detail.last_money_icon}
                            </p>
                            <img src={quesMark} alt="quesMark" />
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="venue_about_section">
                  <div className="tabs row m-0 p-0">
                    <div className="tab-buttons col-lg-3">
                      <button
                        className={activeTab === "about" ? "active" : ""}
                        onClick={() => setActiveTab("about")}
                      >
                        About
                      </button>
                      <button
                        className={activeTab === "menu" ? "active" : ""}
                        onClick={() => setActiveTab("menu")}
                      >
                        Menu
                      </button>
                      <button
                        className={activeTab === "reviews" ? "active" : ""}
                        onClick={() => setActiveTab("reviews")}
                      >
                        Reviews
                      </button>
                    </div>
                    <div className="tab-content">
                      {activeTab === "about" && (
                        <div className="about_venue_tabContent">
                          {/* <h2>{detail.restaurant_name}</h2> */}
                          {/* <p>{detail.restaurant_full_adrress}</p> */}
                          <h5>About</h5>
                          <ReadMore />
                          <div className="venue_features_section">
                            <h5>Features</h5>
                            <div className="row m-0">
                              {detail.amenities &&
                                detail.amenities.length > 0 &&
                                detail.amenities.map((features, idx) => (
                                  <div
                                    className="col-sm-3 col-4 venue_features_wrapper padding5a576"
                                    key={idx}
                                  >
                                    <img
                                      src={
                                        APL_LINK + ImageLink + features.image
                                      }
                                      alt="{features.venue_feature_name}"
                                    />
                                    <p className="venue_feature_name">
                                      {features.amenities_name}
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <section className="Reviews_section">
                            <div className="menu_wrapper">
                              <div className="menu_wrapper_heading mt-2 mb-2">
                                <h5>Restaurant Menu</h5>
                              </div>
                              {images && images.length > 0 && (
                                <div className="row m-0 p-0">
                                  <div className="menu_image_wrapper col-md-4 col-6 padding5p">
                                    <img
                                      key={2}
                                      src={images[2]}
                                      alt={`menu_img_${2}`}
                                    />
                                  </div>
                                  <div className="menu_image_wrapper col-md-4 d-none d-md-block col-6 padding5p">
                                    <img
                                      key={1}
                                      src={images[1]}
                                      alt={`menu_img_${1}`}
                                    />
                                  </div>
                                  <div className="menu_image_wrapper col-md-4 col-6 padding5p">
                                    <img
                                      key={0}
                                      src={images[0]}
                                      alt={`menu_img_${0}`}
                                    />
                                    <button
                                      className="see_all_buttonMenu"
                                      onClick={() => setIsOpen(true)}
                                    >
                                      See All
                                    </button>
                                  </div>
                                </div>
                              )}
                            </div>
                            {detail.review && (
                              <Reviews
                                tabOpen={activeTab}
                                review={detail.review}
                                venuedata={detail}
                                like_data={like_data}
                                setActiveTab={setActiveTab}
                              />
                            )}
                            {/* {detail.review && detail.review.length > 0 && (
                              <div className="see_more_reviews">
                                <Link onClick={() => setActiveTab("reviews")}>
                                  See more reviews (
                                  {detail.review && detail.review.length})
                                  <img src={right} alt="right" />
                                </Link>
                              </div>
                            )} */}
                          </section>
                        </div>
                      )}
                      {/* {activeTab === "menu" && (
                        <div className="menu_wrapper">
                          <div className="menu_wrapper_heading mt-2 mb-2">
                            <h3>Restaurant Menu</h3>
                          </div>
                          <div className="row">
                            <div className="menu_image_wrapper col-xs-3">
                              {detail.menuimages &&
                                detail.menuimages.length > 0 &&
                                detail.menuimages.map((menu_img, idx) => (
                                  <img
                                    key={idx}
                                    src={
                                      APL_LINK + ImageLink + menu_img.image_name
                                    }
                                    alt="menu_img"
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      )} */}
                      {activeTab === "menu" && (
                        <div className="menu_wrapper">
                          <div className="menu_wrapper_heading mt-2 mb-2 padding5p">
                            <h5>Restaurant Menu</h5>
                          </div>
                          {images && images.length > 0 && (
                            <div className="row m-0">
                              <div className="menu_image_wrapper col-md-4 col-6">
                                <img
                                  key={images.length - 1}
                                  src={images[1]}
                                  alt={`menu_img_${1}`}
                                />
                              </div>
                              <div className="menu_image_wrapper col-md-4 d-none d-md-block col-6">
                                <img
                                  key={2}
                                  src={images[2]}
                                  alt={`menu_img_${2}`}
                                />
                              </div>
                              <div className="menu_image_wrapper col-md-4 col-6">
                                <img
                                  key={0}
                                  src={images[0]}
                                  alt={`menu_img_${0}`}
                                />
                                <button
                                  className="see_all_buttonMenu"
                                  onClick={() => setIsOpen(true)}
                                >
                                  See All
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {isOpen && (
                        // <Lightbox
                        // mainSrc={images.reverse()[photoIndex]}
                        // nextSrc={images[(photoIndex + 1) % images.length]}
                        // prevSrc={
                        //   images[
                        //     (photoIndex + images.length - 1) % images.length
                        //   ]
                        // }
                        // onCloseRequest={() => setIsOpen(false)}
                        // onMovePrevRequest={() =>
                        //   setPhotoIndex(
                        //     (photoIndex + images.length - 1) % images.length
                        //   )
                        // }
                        // onMoveNextRequest={() =>
                        //   setPhotoIndex((photoIndex + 1) % images.length)
                        // }
                        // />
                        <Lightbox
                          open={isOpen}
                          close={() => setIsOpen(false)}
                          index={photoIndex}
                          slides={images.reverse().map((src) => ({ src }))}
                          on={{
                            view: ({ index }) => setPhotoIndex(index),
                          }}
                        />
                      )}
                      {activeTab === "reviews" && (
                        <div>
                          {detail.review && (
                            <Reviews
                              tabOpen={activeTab}
                              review={detail.review}
                              like_data={like_data}
                              venuedata={detail}
                              setActiveTab={setActiveTab}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
              <div
                id="enquiryButtonMobile"
                className="EquiryButtonMobile"
                style={{ display: isFooterVisible ? "none" : " " }}
              >
                <button onClick={toggleModal} className="calnderSHowButton">
                  {!isModalVisible ? "Reserve" : "Close"}
                </button>
              </div>
              <div className="col-lg-4">
                <div className="sticky-container">
                  <div
                    className={
                      isMobile
                        ? `calenday_modelContainermobile ${
                            isModalVisible ? "show" : ""
                          }`
                        : `calenday_modelContainer ${
                            isModalVisible ? "show" : ""
                          }`
                    }
                  >
                    <div className="MobileCrossButton">
                      {" "}
                      <button onClick={closeModal} style={{ border: "none " }}>
                        <img src={crossIcon} alt="crossicon"></img>{" "}
                      </button>
                    </div>

                    <div className="calenday_modelContainer ">
                      {currentStep != 5 && (
                        <div className="calenday_model-section">
                          <div className="calendy_modelHead">
                            <div>
                              <h4>Find a table</h4>
                              <p>Book for free</p>
                            </div>
                            <div className="CloseCallnder">
                              <button onClick={toggleModal}>
                                {isModalVisible ? (
                                  <img
                                    src={crossCalander}
                                    alt="crossicon"
                                  ></img>
                                ) : null}
                              </button>
                            </div>
                            {/* <button onClick={toggleModal}>
                              {isModalVisible ? "X" : null}
                            </button> */}
                          </div>
                          {currentStep !== 5 && totalbookingtoday > 0 ? (
                            <span className="todays_booking">
                              🔥 Already {totalbookingtoday} bookings today
                            </span>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </div>
                      )}
                      <div className="calenday_modelSubHead">
                        {/* {currentStep === 1 && ( */}
                        <div className="divContainerBook">
                          <div className="d-flex justify-content-between backgrwh">
                            {/* First Step */}
                            <div className="containerSteps">
                              <span
                                className="steps firstStep"
                                onClick={() => handleclickbackstep(0, "")}
                              >
                                <span className="display_contents">
                                  <img src={calendarfrom} alt="calendarfrom" />
                                  <p>Date</p>
                                </span>
                              </span>
                              {currentStep == 1 && (
                                <div className="rhombus"></div>
                              )}
                              {currentStep == 2 && (
                                <div className="rhombus"></div>
                              )}
                            </div>
                            {currentStep >= 3 && (
                              <div className="containerSteps">
                                <div
                                  className={` ${
                                    currentStep >= 3 ? "steps" : ""
                                  }`}
                                >
                                  {currentStep >= 3 && (
                                    <img
                                      src={rightArrowWhite}
                                      alt="rightArrowWhite"
                                      className="rightArrowWhite"
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                            {/* Second Step */}
                            <div className="containerSteps">
                              <span
                                className={` ${
                                  currentStep >= 3 ? "steps" : ""
                                }`}
                              >
                                <span
                                  className="display_contents"
                                  onClick={() => handleclickbackstep(1, "")}
                                >
                                  {currentStep >= 3 ? (
                                    <img src={timerClockW} alt="timerClock" />
                                  ) : (
                                    <img src={timerClock} alt="timerClock" />
                                  )}
                                </span>
                              </span>
                              {currentStep == 3 && (
                                <div className="rhombus"></div>
                              )}
                            </div>
                            {(currentStep < 3 || currentStep >= 4) && (
                              <div className="containerSteps">
                                <div
                                  className={` ${
                                    currentStep >= 4 ? "steps" : ""
                                  }`}
                                >
                                  <>
                                    {currentStep >= 4 ? (
                                      <img
                                        src={rightArrowWhite}
                                        alt="rightArrowWhite"
                                        className="rightArrowWhite"
                                      />
                                    ) : (
                                      <img
                                        src={rightArrowB}
                                        alt="rightArrowWhite"
                                        className="rightArrowWhite"
                                      />
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                            {/* Trird Step */}
                            <div className="containerSteps">
                              <span
                                className={` ${
                                  currentStep >= 4 ? "steps" : ""
                                }`}
                              >
                                <span
                                  className="display_contents"
                                  onClick={() =>
                                    handleclickbackstep(2, selectedGuest)
                                  }
                                >
                                  {currentStep >= 4 ? (
                                    <img
                                      src={personCalendar}
                                      alt="personCalendar"
                                    />
                                  ) : (
                                    <img
                                      src={personCalendarB}
                                      alt="personCalendar"
                                    />
                                  )}
                                </span>
                              </span>
                              {currentStep == 4 && (
                                <div className="rhombus"></div>
                              )}
                            </div>
                            {(currentStep < 4 || currentStep >= 5) && (
                              <div className="containerSteps">
                                <div
                                  className={` ${
                                    currentStep >= 5 ? "steps" : ""
                                  }`}
                                >
                                  <>
                                    {currentStep >= 5 ? (
                                      <img
                                        src={rightArrowWhite}
                                        alt="rightArrowWhite"
                                        className="rightArrowWhite"
                                      />
                                    ) : (
                                      <img
                                        src={rightArrowB}
                                        alt="rightArrowWhite"
                                        className="rightArrowWhite"
                                      />
                                    )}
                                  </>
                                </div>
                              </div>
                            )}
                            {/* Fourth Step */}
                            <div className="containerSteps">
                              <span
                                className={` lastStep ${
                                  currentStep == 5 ? "steps" : ""
                                }`}
                              >
                                <span className="display_contents">
                                  {currentStep == 5 ? (
                                    <img src={preview} alt="preview" />
                                  ) : (
                                    <img src={previewB} alt="preview" />
                                  )}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                        {/* )} */}
                      </div>
                      <div className={`calenday_modelScreen`}>
                        {currentStep === 1 && (
                          <div className="calenderDiv">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateCalendar
                                value={value_date}
                                onChange={(newValue) => {
                                  handleclickstep(1, newValue);
                                }}
                                minDate={dayjs()} // Optional: Set minimum selectable date
                              />
                            </LocalizationProvider>
                          </div>
                        )}
                        {currentStep === 2 && (
                          <div className="contianerTimings">
                            <div className="">
                              <h6 className="calendar_modal_heading">
                                Booking Time
                              </h6>
                              <span className="venuePage_venue_capacity_wrapper">
                                <div className="time_discount_container_detailedVenue2">
                                  {errorform.error == true && data !== null ? (
                                    data.slice(0, -1).map((item, index) => {
                                      if (item.start_stop_time_status === 0) {
                                        if (
                                          item.online_booking_time_status === 0
                                        ) {
                                          return (
                                            <div
                                              key={index}
                                              className="time_discount_section"
                                              onClick={() =>
                                                handleclickstep(
                                                  2,
                                                  item.primary_id +
                                                    "~@~" +
                                                    item.start_time +
                                                    "~@~" +
                                                    item.per_discount
                                                )
                                              }
                                            >
                                              <div className="time_section2">
                                                <p>
                                                  {formatTimeintotwodigit(
                                                    item.start_time
                                                  )}
                                                </p>
                                              </div>
                                              {item.per_discount > 0 ? (
                                                <div className="discount_section2">
                                                  <p>-{item.per_discount}%</p>
                                                </div>
                                              ) : (
                                                <div className="discount_section2">
                                                  <p>N/A</p>
                                                </div>
                                              )}
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              key={index}
                                              className="time_discount_section block_table"
                                            >
                                              <div className="time_section">
                                                <p>
                                                  {formatTimeintotwodigit(
                                                    item.start_time
                                                  )}
                                                </p>
                                              </div>
                                              {item.per_discount > 0 ? (
                                                <div className="discount_section">
                                                  <p>-{item.per_discount}%</p>
                                                </div>
                                              ) : (
                                                <div className="discount_section">
                                                  <p>N/A</p>
                                                </div>
                                              )}
                                            </div>
                                          );
                                        }
                                      }
                                    })
                                  ) : (
                                    <div className="time_discount_section padding_no_show_msg">
                                      {errormsg}
                                    </div>
                                  )}
                                </div>
                              </span>
                            </div>
                          </div>
                        )}
                        {currentStep === 3 && (
                          <div className="contianerTimings">
                            <div className="wrapper_calendar_modal">
                              <h6 className="calendar_modal_heading">
                                Number of Guests
                              </h6>
                              <div className="resrvDateSelect">
                                <ul>
                                  {Array.from(
                                    { length: guest_length },
                                    (_, index) => index + 1
                                  ).map((digit, index) => (
                                    <li key={index}>
                                      <div
                                        className={`dateBox ${
                                          selectedGuest === index + 1
                                            ? "selectedFormItems"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          SelectedChangeGuest(index + 1, "1")
                                        }
                                      >
                                        <p>{index + 1}</p>
                                      </div>
                                    </li>
                                  ))}

                                  <li
                                    className={`${
                                      addCustomGuest ? " " : "hideInput"
                                    }`}
                                  >
                                    <div className="customRsrvInput">
                                      <input
                                        type="text"
                                        maxLength={3}
                                        name="custom_pet_count"
                                        placeholder="No. of Guests"
                                        onChange={(e) =>
                                          SelectedChangeGuest(e, "2")
                                        }
                                        className="addInputBtn"
                                      />
                                    </div>
                                  </li>
                                  {showAddbutton && !addCustomGuest && (
                                    <li>
                                      <div
                                        // addInputBtn
                                        className={` dateBox addInputBtn ${
                                          addCustomGuest ? "hideInput" : ""
                                        }`}
                                        onClick={addCustomGuestInput}
                                      >
                                        <img src={AddBtn} alt="add btn" />
                                      </div>
                                    </li>
                                  )}
                                </ul>
                              </div>
                              {/* ===== */}
                              <h6 className="calendar_modal_heading">
                                Number of Children
                              </h6>

                              <div className="resrvDateSelect">
                                <ul>
                                  {Array.from(
                                    { length: child_length },
                                    (_, index) => index + 1
                                  ).map((digit, index) => (
                                    <li key={index}>
                                      <div
                                        className={`dateBox ${
                                          selectedChild === index
                                            ? "selectedFormItems"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          SelectedChangeChild(index, "1")
                                        }
                                      >
                                        <p>{index}</p>
                                      </div>
                                    </li>
                                  ))}
                                  <li
                                    className={`${
                                      addCustomChild ? " " : "hideInput"
                                    }`}
                                  >
                                    <div className="customRsrvInput">
                                      <input
                                        type="text"
                                        maxLength={3}
                                        name="custom_child_count"
                                        onBlur={(e) =>
                                          SelectedChangeChild(e, "2")
                                        }
                                        placeholder="No. of Child"
                                        className="addInputBtn"
                                      />
                                    </div>
                                  </li>
                                  {showAddbutton2 && !addCustomChild && (
                                    <li>
                                      <div
                                        className={`dateBox addInputBtn ${
                                          addCustomChild ? "hideInput" : ""
                                        }`}
                                        onClick={addCustomChildInput}
                                      >
                                        <img src={AddBtn} alt="addbtn" />
                                      </div>
                                    </li>
                                  )}
                                </ul>
                              </div>

                              <h6 className="calendar_modal_heading">
                                Number of Pets
                              </h6>
                              {/* <input type="phone" placeholder="Enter no of pets.." /> */}
                              <div className="resrvDateSelect">
                                <ul>
                                  {Array.from(
                                    { length: pet_length },
                                    (_, index) => index + 1
                                  ).map((digit, index) => (
                                    <li key={index}>
                                      <div
                                        className={`dateBox ${
                                          selectedpet === index
                                            ? "selectedFormItems"
                                            : ""
                                        }`}
                                        onClick={() =>
                                          SelectedChangePet(index, "1")
                                        }
                                      >
                                        <p>{index}</p>
                                      </div>
                                    </li>
                                  ))}
                                  <li
                                    className={`${
                                      addCustomPet ? " " : "hideInput"
                                    }`}
                                  >
                                    <div className="customRsrvInput">
                                      <input
                                        type="text"
                                        maxLength={3}
                                        name="custom_pet_count"
                                        onChange={(e) =>
                                          SelectedChangePet(e, "2")
                                        }
                                        placeholder="No. of Pets"
                                        className="addInputBtn"
                                      />
                                    </div>
                                  </li>
                                  {showAddbutton3 && !addCustomPet && (
                                    <li className="h-100">
                                      <div
                                        className={`addInputBtn dateBox ${
                                          addCustomPet ? "hideInput" : ""
                                        }`}
                                        onClick={addCustomPetInput}
                                      >
                                        <img
                                          src={AddBtn}
                                          alt="Barley's Dashboard"
                                        />
                                      </div>
                                    </li>
                                  )}
                                </ul>
                              </div>

                              <span>
                                <Link
                                  onClick={() =>
                                    handleclickstep(3, selectedGuest)
                                  }
                                >
                                  Next
                                </Link>
                              </span>
                            </div>
                          </div>
                        )}
                        {currentStep === 4 && (
                          <div className="contianerTimings">
                            <div className="wrapper_calendar_modal">
                              <h6 className="calendar_modal_heading">
                                Review Details
                              </h6>
                              <div className="details_step_calendar_modal">
                                <span className="row m-0">
                                  <h6 className="col-4">Date: </h6>
                                  <p className="col-8">
                                    {formatDateStringdot(selectedDate)} |{" "}
                                    {selectedTime}
                                  </p>
                                </span>
                                <span className="row m-0">
                                  <h6 className="col-4">Person: </h6>
                                  <p className="col-8">{selectedGuest}</p>
                                </span>
                                <span className="row m-0">
                                  <h6 className="col-4">Children: </h6>
                                  <p className="col-8">{selectedChild}</p>
                                </span>
                                <span className="row m-0">
                                  <h6 className="col-4">Pets: </h6>
                                  <p className="col-8">{selectedpet}</p>
                                </span>
                                <span className="row m-0">
                                  <h6 className="col-4">Discount: </h6>
                                  <p className="col-8">{selectedDiscount}%</p>
                                </span>

                                <input
                                  type="text"
                                  placeholder="Add Instruction/Comment"
                                  name="order_comment"
                                  maxLength={300}
                                  id="order_comment"
                                  onInput={handleIaphabetnumberChange}
                                />

                                <span style={{ alignItems: "flex-start" }}>
                                  {" "}
                                  <input
                                    style={{ marginTop: "0.2rem" }}
                                    type="checkbox"
                                    className="checkBoxUnique"
                                    name="checkSurfaceEnvironment-1"
                                    id="checkSurfaceEnvironment-1"
                                  />
                                  <label>
                                    I agree to the{" "}
                                    <span
                                      style={{
                                        display: "inline-block",
                                        color: "var(--primary-color)",
                                        backgroundColor: "transparent",
                                        padding: "0",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        window.open("/termsOfUse", "_blank")
                                      }
                                    >
                                      Terms & Conditions
                                    </span>{" "}
                                    and{" "}
                                    <span
                                      style={{
                                        color: "var(--primary-color)",
                                        backgroundColor: "transparent",
                                        padding: "0",
                                        display: "inline-block",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        window.open("/privacyPolicy", "_blank")
                                      }
                                    >
                                      Privacy Policy
                                    </span>
                                  </label>
                                </span>
                                <Link onClick={function_save}>Book Now</Link>
                              </div>
                            </div>
                          </div>
                        )}
                        {currentStep === 5 && (
                          <div className="stepBg">
                            <div className="final_step_wrapper">
                              <h6>Reservation </h6>
                              <span>
                                <p>
                                  {formatDateStringdot(selectedDate)} •{" "}
                                  {selectedGuest} Person • {selectedTime}
                                </p>
                              </span>

                              <div className="confirmed_booking_span">
                                <h6>Booking Confirmed! </h6>
                                <desc>{showmsgforbook}</desc>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="want_more_section">
                    <span>
                      <h6>Can’t find the available you want?</h6>
                      <p>Find a table at a similar restaurant </p>
                    </span>
                    <img src={rightArrowWhite} alt="right" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5"></section>

        <Footer />
      </div>

      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        className="text-center submtformmdl"
        centered
      >
        <Modal.Body className="bg-gray-100 p-6">
          <div className="succSSimg">
            <img src={successPaymentGif} alt="successPaymentGif" />
          </div>
          <h4 className="succMsg">Booking Confirmed</h4>

          <div className="flex flex-col items-center">
            <p className="text-lg font-semibold Scndhead ">
              Your reservation has been confirmed with DFOODO!
            </p>
            <p className="text-gray-600 thirdhead">
              Thank you for choosing us. We look forward to serving you!
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DetailedVenue;

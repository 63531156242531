import React, { useState, useEffect } from "react";
import backButton from "../Assets/backButton.svg";
import tooltipIcon from "../Assets/tooltip.svg";
import Swal from "sweetalert2";
import "./Css/Newonboading.css";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep8 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const [PerDayMaximum, setPerDayMaximum] = useState("");
  const [ApprovalPersonLimit, setApprovalPersonLimit] = useState("");
  const [BeforeBookingMin, setBeforeBookingMin] = useState("");
  const [TableTurnover, setTableTurnover] = useState("");
  const [RestaurantPrice, setRestaurantPrice] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFocused2, setIsFocused2] = useState(false);
  const [isFocused3, setIsFocused3] = useState(false);
  const [isFocused4, setIsFocused4] = useState(false);
  const [isFocused5, setIsFocused5] = useState(false);
  const [perDayMaxError, setPerDayMaxError] = useState("");
  const [approvalLimitError, setApprovalLimitError] = useState("");
  const [bookingCutOffError, setBookingCutOffError] = useState("");
  const [tableTurnoverError, setTableTurnoverError] = useState("");
  const [restaurantPriceError, setRestaurantPriceError] = useState("");
  const [slotSelectionError, setSlotSelectionError] = useState("");
  const handleRadioChange = (index) => {
    setSelectedOption(index); // Update selected option
  };

  const handleNext = () => {
    let hasError = false;

    // Reset errors
    setPerDayMaxError("");
    setApprovalLimitError("");
    setBookingCutOffError("");
    setTableTurnoverError("");
    setRestaurantPriceError("");
    setSlotSelectionError("");

    // Validate fields and set specific error messages
    if (PerDayMaximum === "" || PerDayMaximum <= 0) {
      setPerDayMaxError("Please enter a valid maximum seat availability.");
      hasError = true;
    }
    if (ApprovalPersonLimit === "" || ApprovalPersonLimit <= 0) {
      setApprovalLimitError("Please enter a valid approval person limit.");
      hasError = true;
    }
    if (BeforeBookingMin === "" || BeforeBookingMin <= 0) {
      setBookingCutOffError("Booking cutoff time must be greater than zero.");
      hasError = true;
    }
    if (TableTurnover === "" || TableTurnover <= 0) {
      setTableTurnoverError("Please enter a valid table turnover time.");
      hasError = true;
    }
    if (RestaurantPrice === "" || RestaurantPrice <= 1) {
      setRestaurantPriceError("Restaurant price must be greater than ₹1.");
      hasError = true;
    }
    if (selectedOption === null) {
      setSlotSelectionError("Please select a time slot.");
      hasError = true;
    }

    if (!hasError) {
      handleSaveChangesdynamic();
    }
  };
  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("per_day_maximum", PerDayMaximum);
    form_data.append("approval_person_limit", ApprovalPersonLimit);
    form_data.append("before_booking_min", BeforeBookingMin);
    form_data.append("booking_slot_interval", selectedOption);
    form_data.append("table_turnover", TableTurnover);
    form_data.append("restaurant_price", RestaurantPrice);
    form_data.append("step_save", "8");
    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setPerDayMaximum(
              Response.data.message.restaurant_data[0].per_day_maximum
            );
            setApprovalPersonLimit(
              Response.data.message.restaurant_data[0].approval_person_limit
            );
            setBeforeBookingMin(
              Response.data.message.restaurant_data[0].before_booking_min
            );
            setTableTurnover(
              Response.data.message.restaurant_data[0].table_turnover
            );
            setSelectedOption(
              Response.data.message.restaurant_data[0].booking_slot_interval
            );
            setRestaurantPrice(
              Response.data.message.restaurant_data[0].restaurant_price
            );
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [focusedField, setFocusedField] = useState(null);
  const handleFocus = (field) => {
    setFocusedField(field);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };
  const isNextButtonDisabled =
    PerDayMaximum === "" ||
    ApprovalPersonLimit === "" ||
    BeforeBookingMin === "" ||
    TableTurnover === "" ||
    RestaurantPrice === "" ||
    selectedOption === null;

  const isFormValid = !isNextButtonDisabled;
  return (
    <div className="mt-3">
      <div className="containerRight">
        <div className="rightHeading">
          <div className="d-flex BackHeading">
            <button onClick={prevStep}>
              <img src={backButton} alt="Back Button" />
            </button>
            <h3>Restaurant Seat Availability / Price</h3>
          </div>
          <p>Add No. of seat availability on your restaurant</p>
        </div>
        <div className="overflowHeight">
          {[
            {
              label: "Per Day Maximum Seat*",
              placeholder: "Per Day Maximum Seat*",

              value: PerDayMaximum,
              setValue: setPerDayMaximum,
              error: perDayMaxError,
              focus: isFocused5,
              setFocus: setIsFocused5,
              tooltip: "Per Day Maximum Seating: The total number of seats...",
              max_Length: 3,
            },
            {
              label: "Approval Person Limit*",
              placeholder: "Approval Person Limit*",
              value: ApprovalPersonLimit,
              setValue: setApprovalPersonLimit,
              error: approvalLimitError,
              focus: isFocused4,
              setFocus: setIsFocused4,
              tooltip: "Approval Person Limit: The maximum number of people...",
              max_Length: 3,
            },
            {
              label: "Booking Cut off Time*",
              placeholder: "Booking Cut off Time*",
              value: BeforeBookingMin,
              setValue: setBeforeBookingMin,
              error: bookingCutOffError,
              focus: isFocused3,
              setFocus: setIsFocused3,
              max_Length: 2,
              tooltip:
                "Booking Cut-Off Time: The latest time for reservations.",
            },

            {
              label: "Table Turn Over Time*",
              placeholder: "Table Turn Over Time*",
              value: TableTurnover,
              setValue: setTableTurnover,
              error: tableTurnoverError,
              focus: isFocused2,
              setFocus: setIsFocused2,
              max_Length: 2,
              tooltip:
                "Table turnover time: The duration for preparing the table...",
            },
            {
              label: "Approx Average Price for Two*",
              placeholder: "₹ Approx Average Price for Two*",
              value: RestaurantPrice,
              setValue: setRestaurantPrice,
              error: restaurantPriceError,
              focus: isFocused,
              setFocus: setIsFocused,
              max_Length: 5,
              tooltip:
                "Average price for two people dining at your restaurant.",
            },
          ].map(
            (
              {
                label,
                placeholder,
                value,
                setValue,
                error,
                focus,
                setFocus,
                tooltip,
                max_Length,
              },
              index
            ) => (
              <div
                key={index}
                className="col-sm-9 mb-3"
                style={{ position: "relative" }}
              >
                {/*{(focus || value) && (
                  <label className="HeadBussiness">{label}</label>
                )} */}
                <label className="HeadBussiness">{label}</label>
                <div className="col">
                  <div
                    className={`seattooltip ${focus ? "input-focused" : ""}`}
                  >
                    <input
                      type="text"
                      maxLength={max_Length}
                      placeholder={placeholder}
                      className="form-control ActiveFormCOntrol"
                      defaultValue={value || ""}
                      onChange={(e) => {
                        const sanitizedValue = e.target.value
                          .trim()
                          .replace(/\D/g, "");
                        setValue(sanitizedValue); // Update the state as the user types
                      }}
                      onFocus={() => setFocus(true)}
                      onBlur={() => setFocus(false)}
                    />

                    <img src={tooltipIcon} alt="tooltip" />
                    <div className="tooltiptext">{tooltip}</div>
                  </div>
                  {error && (
                    <span className="error-message" style={{ color: "red" }}>
                      {error}
                    </span>
                  )}
                </div>
              </div>
            )
          )}

          <div className="col-sm-9 mb-1 mt-4">
            <label className="timeShort">
              Manage Reservation with Time slots of*
            </label>
          </div>
          <div className="col-sm-9 mb-3">
            <div className="ContainerCheckbox">
              {[15, 30].map((option) => (
                <div key={option} className="d-flex gap-1 timeSlots">
                  <input
                    type="radio"
                    checked={selectedOption === option}
                    onChange={() => handleRadioChange(option)}
                  />
                  <p className="m-0 p-0">{option} minutes</p>
                </div>
              ))}
            </div>
          </div>

          <div className="footerbtttn fixNextButton">
            {/* <div className="skipbutton" onClick={handleNext}>
              <button>Skip</button>
            </div> */}
            <div className="container-lg d-flex justify-content-end">
              <div className="NextButton">
                <button
                  onClick={handleNext}
                  // disabled={isNextButtonDisabled}
                  // style={{
                  //   margin: 0
                  //   opacity: isNextButtonDisabled ? 0.5 : 1,
                  //   pointerEvents: isNextButtonDisabled ? "none" : "auto",
                  // }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div
            className={`fixNextButton  ${
              isFormValid ? " nonEmptyBttn" : "emptyFiled"
            }`}
            style={{ marginTop: "0px" }}
          >
            <div className="container-lg d-flex justify-content-end">
              <button onClick={handleNext}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboadingstep8;

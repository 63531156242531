import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Mobilefotter from "./Mobilefotter.js";
import DOMPurify from "dompurify";
import "../Components/Css/TermsOfuse.css";
import rightTickGreen from "../Assets/rightTickGreen.png";
import circleIcn from "../Assets/circleIcn.svg";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import {
  server_post_data,
  get_terms_privacy_data,
} from "../ServiceConnection/serviceconnection";
const TermsOfuse = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [terms, setTerms] = useState();
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_terms_privacy_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setTerms(Response.data.message[0].terms_condition);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);
  return (
    <div className="howitworks_wrapper">
      <Header />
      <div className=" container-lg headerSection_howitWorks col-lg-12">
        <div className="terms_imageContainer">
          <h3>Terms and Conditions </h3>
        </div>
      </div>
      <div className="container-lg">
        <div className="ContrrTnC">
          {" "}
          <div className="headingAgremnt">
            <h3>User Agreement</h3>
            <h4>Updated on [01/01/2025]</h4>
          </div>
          <div className="topContnt">
            <p>
              These <span>Terms of Use</span>("Terms") constitute a legally
              binding agreement between you and{" "}
              <span>
                {" "}
                <a
                  href="https://www.triosoft.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Triosoft Technologies Pvt Ltd
                </a>{" "}
              </span>
              (the "Company", "Dfoodo") regarding your use of the{" "}
              <span>Dfoodo website and mobile applications </span> (the
              "Service").
            </p>
            <p>
              By <span>accessing or using our platform, </span> and clicking
              <span>I Agree</span>you <span>confirm </span>that you have read,
              understood, and agreed to these Terms. If you
              <span>do not agree </span>please <span>do not use Dfoodo. </span>
            </p>
          </div>
          <div className="centerConttntt">
            <h2>1. Definitions</h2>
            <p>For the purpose of this Agreement:</p>
            <ul>
              <li>
                <span>User</span>or<span>You</span> refers to
                <span> any individual using Dfoodo </span>
                for restaurant bookings
              </li>
              <li>
                <span>Services</span>refers to<span>all features provided</span>{" "}
                by Dfoodo, including
                <span>
                  table reservations, restaurant discovery, reviews, and user
                  notifications.
                </span>
              </li>
              <li>
                <span>Account</span>refers to a <span>registered profile</span>
                required to access Dfoodo.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>2. Acceptance & Updates to Terms</h2>

            <ul>
              <li>
                These <span>Terms</span>apply to<span>all users </span> of
                Dfoodo.
              </li>
              <li>
                Dfoodo<span>reserves the right</span>to update these Terms at
                <span>any time</span>
              </li>
              <li>
                <span>Continued use </span> of the platform after changes
                <span> implies your acceptance.</span>
              </li>
              <li>
                The
                <span>latest version</span>of these Terms will always be
                available on
                <span> Dfoodo’s Website.</span>
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>3. User Eligibility & Registration</h2>
            <p className="firstPteg">3.1 Age & User Responsibility</p>
            <ul>
              <li>
                You must be <span>18 years or older</span> to register and use
                Dfoodo.
              </li>
              <li>
                If you are<span>under 18,</span> you may only use Dfoodo
                <span>under parental supervision.</span>
              </li>
            </ul>{" "}
            <p>3.2 Account Creation & Login</p>{" "}
            <ul>
              <li>
                <span>Users must register using a mobile number</span>{" "}
                (OTP-based login).
              </li>
              <li>
                <span>Social media logins</span>(Google/Facebook) may be
                introduced in the future.
              </li>
              <li>
                <span>
                  Account sessions remain active until the user logs out.
                </span>
              </li>
            </ul>{" "}
            <p>3.3 Account Security</p>{" "}
            <ul>
              <li>
                Users <span>must keep login credentials confidential.</span>
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>4.Table Reservation System</h2>
            <div className="fouthHad">
              <p className="firstPteg">
                Dfoodo allows users to book tables at partner restaurants via:
              </p>{" "}
              <div className="grenMArkicnnn">
                <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                <label>Dfoodo Website</label>
              </div>
              <div className="grenMArkicnnn">
                <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                <label>Dfoodo Mobile Appe</label>
              </div>
              <div className="grenMArkicnnn">
                <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                <label>WhatsApp & Customer Support</label>
              </div>
            </div>
            <p className="secondPtag">4.1 Reservation Process</p>
            <ul>
              <li>
                Search Restaurants - Browse restaurants by{" "}
                <span>location, cuisine, or ratings.</span>
              </li>
              <li>
                Select Time Slot – Choose <span>date & time</span> for your
                booking.
              </li>
              <li>
                Confirm Reservation – Receive{" "}
                <span> confirmation via SMS or Email or WhatsApp.</span>
              </li>
              <li>
                Select Time Slot – Arrive <span> on time.</span>to claim your
                table.
              </li>
            </ul>{" "}
            <p>4.2 Cancellation & No-Show Policy</p>{" "}
            <ul>
              <li>
                Users{" "}
                <span>
                  must cancel reservations at least 1 hour in advance.
                </span>{" "}
              </li>
              <li>
                <span>Repeated no-shows (3+ times)</span>may result in
                <span>account suspension.</span>
              </li>
              <li>
                If a user
                <span>arrives 30+ minutes late,</span>the restaurant can cancel
                the reservation.
              </li>
            </ul>{" "}
            <p>4.3 Restaurant Cancellation Rights</p>{" "}
            <ul>
              <li>
                Partner restaurants <span>can cancel reservations</span> due to
                operational reasons.
              </li>
              <li>
                Users <span>will be notified </span> of any
                <span> cancellation via SMS and email.</span>
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>5. User Reviews & Ratings</h2>
            <ul>
              <li>
                Users
                <span> can leave reviews.</span>after dining.
              </li>
              <li>
                Reviews<span> must be factual and fair</span>
              </li>
              <li>
                <span>Fake, misleading, or defamatory reviews </span>
                will be removed.
              </li>
              <li>
                Restaurants<span> may respond </span> to reviews but
                <span> cannot demand removal of negative reviews.</span>
              </li>
            </ul>{" "}
          </div>
          <div className="centerConttntt3">
            <h2>6. Privacy & Data Protection</h2>
            <p className="firstPteg">
              By using Dfoodo, you agree to our Privacy Policy, which includes:
            </p>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Personal Data Collection</span>
              <label>– Name, phone number, reservation details.</label>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Location Data </span>
              <label>
                – Used for restaurant recommendations (not shared publicly).
              </label>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span> Third-Party Services</span>
              <label>– Google Maps, Razorpay, SendGrid (email services).</label>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span> Data Protection </span>
              <label>– We implement encryption & strict access controls.</label>
            </div>
            <label>Refer to our Privacy Policy for details.</label>
          </div>
          <div className="centerConttntt3">
            <h2>7. User Conduct & Prohibited Activities</h2>
            <p className="firstPteg">Users must not:</p>
            <div className="redMarkIcn">
              <img src={circleIcn} alt="circleIcn"></img>{" "}
              <span>Make fraudulent reservations.</span>
            </div>
            <div className="redMarkIcn">
              <img src={circleIcn} alt="circleIcn"></img>{" "}
              <span> Harass or threaten </span>
              <label> restaurant staff.</label>
            </div>
            <div className="redMarkIcn">
              <img src={circleIcn} alt="circleIcn"></img>{" "}
              <span>Modify, hack, or misuse</span>
              <label>Dfoodo’s website/app.</label>
            </div>
            <div className="redMarkIcn">
              <img src={circleIcn} alt="circleIcn"></img>{" "}
              <span>Post fake reviews</span>
              <label>or manipulate ratings.</label>
            </div>
            <div className="redMarkIcn">
              <img src={circleIcn} alt="circleIcn"></img>{" "}
              <span>Scrape data or use bots.</span>
            </div>

            <label>
              Any violation may lead to permanent account suspension.
            </label>
          </div>
          <div className="centerConttntt3">
            <h2>8. Service Availability & Liability Disclaimer</h2>
            <p className="firstPteg">
              By using Dfoodo, you agree to our Privacy Policy, which includes:
            </p>
            <ul>
              {" "}
              <li>Dfoodo does not own or operate restaurants.</li>
              <li>
                We only facilitate reservations and are not responsible for:
              </li>
            </ul>

            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Food quality or service.</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Hygiene, safety, or customer experience.</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span> Restaurant issues or delays.</span>
            </div>

            <label>
              Dfoodo is not liable for losses due to restaurant cancellations.
            </label>
          </div>
          <div className="centerConttntt3">
            <h2>9. Intellectual Property Rights</h2>

            <ul>
              {" "}
              <li>
                All <span>logos, designs, and trademarks</span> belong to
                <span>
                  {" "}
                  <a
                    href="https://www.triosoft.ai/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Triosoft Technologies Pvt Ltd
                  </a>{" "}
                </span>
              </li>
              <li>
                Users <span> cannot copy, reproduce, or distribute </span> any
                content
                <span>without permission.</span>
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>10. Governing Law & Dispute Resolution</h2>

            <ul>
              {" "}
              <li>
                Users and Dfoodo{" "}
                <span> agree to resolve disputes amicably</span>
              </li>
              <li>
                This agreement is governed by
                <span> Indian law</span>
              </li>
              <li>
                Legal matters will be handled in
                <span>Bhopal (M.P), India courts.</span>
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>11. Updates to Terms & Conditions</h2>

            <ul>
              {" "}
              <li>
                Dfoodo
                <span> may update these terms </span>at any time.
              </li>
              <li>
                Users will be
                <span>notified </span>of major changes via email.
              </li>
              <li>
                <span> Continued use</span>of Dfoodo implies
                <span> acceptance of the revised terms.</span>
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>12. Contact Information</h2>
            <p className="firstPteg">
              For assistance, complaints, or legal queries, contact:
            </p>
            <div className="contact-info">
              <div className="emailLbll">
                <a
                  href="mailto:support@triosoft.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={13} /> Email :{" "}
                  <span> support@triosoft.ai </span>
                </a>
              </div>
              <div className="emailLbll">
                <a
                  href="tel:+917880016622"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPhone size={13} /> Phone : <span> +91 7880016622 </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Mobilefotter />
      <Footer />
    </div>
  );
};

export default TermsOfuse;

import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Mobilefotter from "./Mobilefotter.js";
import DOMPurify from "dompurify";
import "../Components/Css/TermsOfuse.css";
import rightTickGreen from "../Assets/rightTickGreen.png";
import circleIcn from "../Assets/circleIcn.svg";
import { FaEnvelope, FaPhone } from "react-icons/fa";
import {
  server_post_data,
  get_terms_privacy_data,
} from "../ServiceConnection/serviceconnection";
const PatnerTermCondition = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [terms, setTerms] = useState();
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_terms_privacy_data, fd)
      .then((Response) => {
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setTerms(Response.data.message[0].terms_condition);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);
  return (
    <div className="howitworks_wrapper">
      <Header />
      <div className=" container-lg headerSection_howitWorks col-lg-12">
        <div className="terms_imageContainer">
          <h3>Dfoodo - Terms & Conditions for Partner Restaurants </h3>
        </div>
      </div>
      <div className="container-lg">
        <div className="ContrrTnC">
          {" "}
          <div className="headingAgremnt">
            <h3>Effective Date: [01/01/2025]</h3>
          </div>
          <div className="topContnt">
            <p>
              This <span>Terms of Use</span>agreement ("Agreement") is a
              <span>legally binding contract</span>between
              <span>
                {" "}
                <a
                  href="https://www.triosoft.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Triosoft Technologies Pvt Ltd
                </a>{" "}
              </span>
              ("Dfoodo," "we," "our," "us") and the participating regarding
              <span>the use of Dfoodo’s platform and services.</span> (the
              "Service").
            </p>
            <p>
              By <span>registering on Dfoodo,</span> you acknowledge that you
              have read, understood, and agreed to
              <span>comply with these Terms.</span>
            </p>
          </div>
          <div className="centerConttntt">
            <h2>1. Partner Enrollment & Subscription</h2>
            <p>A. Enrollment Process</p>
            <ul>
              Restaurants must register on Dfoodo and provide accurate business
              details, including:
              <li>
                <span>Restaurant Name, Address, and Contact Details</span>
              </li>
              <li>
                <span>FSSAI License & GSTIN (if applicable)</span>
              </li>
              <li>
                <span>Bank Account Details for Payments </span>
              </li>
              <li>
                <span>Primary Contact Person Details</span>
              </li>
            </ul>
            <p>B. Subscription Fees & Automatic Renewal</p>
            <ul>
              <li>
                <span>Dfoodo does not charge any commission</span>on
                reservations.
              </li>
              <li>
                Partner Restaurants must pay an{" "}
                <span>Annual Subscription Fee of ₹10,000</span>(subject to
                change).
              </li>
              <li>
                <span>
                  Payment is automatically renewed every 12 months through
                </span>{" "}
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <label> UPI Auto-Pay</label>
                </div>
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <label>Credit/Debit Card Auto-Debit</label>
                </div>
              </li>
              <li>
                <span>
                  If the automatic payment fails, restaurants will receive
                  reminders to complete the payment manually.
                </span>
              </li>
            </ul>
            <p>C. Subscription Renewal Policy</p>
            <ul>
              <li>
                Automatic renewal will be
                <span>enabled by default.</span>
              </li>
              <li>
                Partners can
                <span>opt-out of auto-renewal</span>by selecting the manual
                renewal option in their Partner Dashboard.
              </li>
              <li>
                <span>
                  Restaurants will receive subscription renewal reminders:
                </span>{" "}
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <label>30 days before expiration</label>
                </div>
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <label>10 days before expiration</label>
                </div>
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <label>Daily reminders in the last 5 days</label>
                </div>
              </li>
              <li>
                <span>
                  If the subscription is not renewed, the restaurant’s listing
                  and access to services will be suspended until payment is
                  received.
                </span>
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>2. Reservation & No-Show Policy</h2>
            <p>A. Managing Reservations</p>
            <ul>
              <li>
                Partner Restaurants will receive{" "}
                <span>
                  customer reservations via the Dfoodo Dashboard & App.
                </span>
              </li>
              <li>
                Restaurants <span> must confirm, modify, or cancel</span>
                reservations in real-time through the system.
              </li>
              <li>
                <span>Automatic reservation confirmations & reminders</span>will
                be sent to customers.
              </li>
            </ul>
            <p>B. Partner Commitment & No-Show Policy</p>
            <ul>
              <li>
                <span>Dfoodo does not charge any commission</span>on
                reservations.
              </li>
              <li>
                <span>Restaurants must honor all confirmed reservations, </span>
                unless modified or canceled by the user.
              </li>
              <li>
                If a restaurant
                <span>denies a confirmed reservation</span> ithout valid reason
                more than 3 times, Dfoodo reserves the right to terminate the
                partnership.
              </li>
              <li>
                Restaurants must update availability in real-time to prevent
                overbooking.
              </li>
              <li>
                <span>
                  If the automatic payment fails, restaurants will receive
                  reminders to complete the payment manually.
                </span>
              </li>
            </ul>
            <p>C. Minimum Seat Allocation Requirement </p>
            <ul>
              <li>
                Partner Restaurants must
                <span>
                  allocate at least 20% of their total seating capacity
                </span>
                for reservations made through Dfoodo.
              </li>
              <li>
                The remaining seats can be
                <span>managed at the restaurant’s discretion </span>
              </li>
            </ul>
            <p>D. Cancellation Policy </p>
            <ul>
              <li>
                Restaurants must allow
                <span>
                  cancellations up to 30 minutes before the reservation time.
                </span>
              </li>
              <li>
                If a cancellation policy applies,it
                <span>must be clearly displayed </span>in the Partner Dashboard.
              </li>
              <li>
                Customers
                <span>
                  {" "}
                  <span>must be clearly displayed </span>in the Partner
                  Dashboard.
                </span>
                for cancellations made within this timeframe unless explicitly
                stated in the restaurant’s policy.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>3. Dfoodo Partner Dashboard & App Usage</h2>
            <p>A. Features & Access</p>
            <ul className="listStylineNonee">
              <li>
                The
                <span>Dfoodo Partner Dashboard</span> (Web & Mobile App)
                includes
              </li>
              <li>
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <span>Reservation Management</span>
                  <label> – Accept, modify, or cancel bookings</label>
                </div>
              </li>
              <li>
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <span> Table Management </span>
                  <label> – Track live table availability</label>
                </div>
              </li>
              <li>
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <span>Analytics & Reports</span>
                  <label>
                    {" "}
                    – Access reservation history and performance insights
                  </label>
                </div>
              </li>
              <li>
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <span>Customer Engagement</span>
                  <label> – Send special offers and loyalty benefits</label>
                </div>
              </li>
            </ul>
            <p>B. Compliance with Dfoodo Guidelines</p>
            <ul>
              <li>
                Restaurants
                <span> must ensure timely updates</span>to availability, menu,
                and pricing.
              </li>
              <li>
                Any
                <span>fraudulent, misleading, or unethical activity</span>
                will result in suspension or termination.
              </li>
              <li>
                Partner accounts
                <span> must be kept secure</span> – sharing login credentials is
                prohibited.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>4. Payment Terms & Refund Policy</h2>
            <p>A. Subscription Payment Terms</p>
            <ul>
              <li>
                Payment must be made in full before activation of services.
              </li>
              <li>
                Payment can be
                <span>processed via UPI, Net Banking, Credit/Debit Cards.</span>
              </li>
              <li>
                <span>Auto-renewal is enabled</span>for seamless service
                continuation.
              </li>
            </ul>
            <p>B. Refunds & Termination</p>
            <ul>
              <li>
                <span> Subscription Fees are non-refundable. </span>
              </li>
              <li>
                If a restaurant
                <span> wants to discontinue,</span>, they must disable
                auto-renewal at least 30 days before renewal.
              </li>
            </ul>
            <p>C. Termination by Restaurant</p>
            <ul>
              <li>
                A restaurant may discontinue its partnership with Dfoodo by
                providing
                <span>30 days' notice.</span>
              </li>
              <li>
                <span>No refunds will be issued</span>for unused portions of the
                subscription period.
              </li>
            </ul>
          </div>
          <div className="centerConttntt">
            <h2>5. Compliance & Liabilities</h2>
            <p>A. Restaurant Responsibilities</p>
            <ul>
              <li>Partners must</li>
              <span>
                comply with all local laws, health, and safety regulations.
              </span>
              <li>
                Partners
                <span>
                  must not engage in fraudulent, misleading, or unethical
                  practices.
                </span>
              </li>
              <li>
                Restaurants are
                <span>
                  fully responsible for customer experiences and food quality.
                </span>
              </li>
            </ul>
            <p>B. Termination & Account Suspension</p>
            <ul className="listStylineNonee">
              Dfoodo reserves the right to suspend or terminate a Partner’s
              account for:
              <li>
                {" "}
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <span> Repeated customer complaints</span>
                </div>
              </li>
              <li>
                {" "}
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <span>Failure to honor reservations</span>
                  <label> (more than 3 times)</label>
                </div>
              </li>
              <li>
                {" "}
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <span> Engaging in unethical business practices</span>
                </div>
              </li>
              <li>
                {" "}
                <div className="grenMArkicnnn">
                  <img src={rightTickGreen} alt="rightTickGreen"></img>{" "}
                  <span>Non-payment of subscription fees</span>
                </div>
              </li>
              <label>
                Any legal disputes arising from these terms shall be
                <span>
                  {" "}
                  subject to the jurisdiction of Bhopal, Madhya Pradesh (India).
                </span>
              </label>
              <label>
                If terminated, no refund will be issued, and future
                participation may be restricted.
              </label>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>6. Contact & Support</h2>
            <p className="firstPteg">
              For assistance, reach out to <span> Dfoodo Partner Support:</span>
            </p>
            <div className="contact-info">
              <div className="emailLbll">
                <a
                  href="mailto:support@triosoft.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={13} /> Email :{" "}
                  <span> support@triosoft.ai </span>
                </a>
              </div>
              <div className="emailLbll">
                <a
                  href="tel:+917880016622"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPhone size={13} /> Phone : <span> +91 7880016622 </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Mobilefotter />
      <Footer />
    </div>
  );
};

export default PatnerTermCondition;

import React, { useState, useEffect } from "react";
import backButton from "../Assets/backButton.svg";
import "./Css/Newonboading.css";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { handleError, validateMobileplus } from "../CommonJquery/CommonJquery";
import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
} from "../ServiceConnection/serviceconnection";
let staff_id = "0";
let default_restaurant_id_staff = "0";
const Onboadingstep2 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  const [showLoader, setshowLoader] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const [contactInfo, setContactInfo] = useState({
    countryCode: "+91",
    phoneNumber: "",
    email: "",
  });
  const [errors, setErrors] = useState({});
  const [shake, setShake] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    let value_ddd = value;
    if (name === "countryCode") {
      value_ddd = validateMobileplus(value);
    }
    setContactInfo((prev) => ({ ...prev, [name]: value_ddd }));
    if (value.trim() !== "") {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }
  };

  const handleMobilevalidation = (e) => {
    const { name, value } = e.target;

    if (name === "phoneNumber" && !/^\d*$/.test(value)) {
      return;
    }

    handleChange(e);
  };
  const handleEmailChange = (e) => {
    const { name, value } = e.target;

    setContactInfo((prev) => ({ ...prev, [name]: value }));

    // Email validation
    if (!emailRegex.test(value)) {
      setErrors((prev) => ({ ...prev, [name]: true }));
    } else {
      setErrors((prev) => ({ ...prev, [name]: false }));
    }

    // Clear error if input is not empty
    if (value.trim() === "") {
      setErrors((prev) => ({ ...prev, [name]: true }));
    }
  };

  const handleFocus = (field) => {
    setFocusedField(field);
  };

  const handleBlur = () => {
    setFocusedField(null);
  };

  const handleNextStep = () => {
    const newErrors = {};
    let hasError = false;

    Object.keys(contactInfo).forEach((field) => {
      console.log(contactInfo[field]);
      console.log(field);
      if (
        field !== "email" &&
        (contactInfo[field] === null || contactInfo[field].trim() === "")
      ) {
        newErrors[field] = true;
        hasError = true;
      }
    });

    if (
      contactInfo.email.trim() === "" ||
      !emailRegex.test(contactInfo.email)
    ) {
      newErrors.email = true;
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      setShake(true);
      setTimeout(() => setShake(false), 500);
    } else {
      handleSaveChangesdynamic();
    }
  };

  const handleSaveChangesdynamic = async () => {
    setshowLoader(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("restaurant_other_no", contactInfo.phoneNumber);
    form_data.append("restaurant_country_code", contactInfo.countryCode);
    form_data.append("restaurant_email", contactInfo.email);
    form_data.append("step_save", "2");

    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setContactInfo({
              countryCode:
                Response.data.message.restaurant_data[0]
                  .restaurant_country_code !== null
                  ? Response.data.message.restaurant_data[0]
                      .restaurant_country_code
                  : "+91",
              phoneNumber:
                Response.data.message.restaurant_data[0]
                  .restaurant_mobile_no === null
                  ? ""
                  : Response.data.message.restaurant_data[0]
                      .restaurant_mobile_no,
              email:
                Response.data.message.restaurant_data[0].restaurant_email ===
                null
                  ? ""
                  : Response.data.message.restaurant_data[0].restaurant_email,
            });
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const isFormValid =
    contactInfo.countryCode.trim() !== "" &&
    contactInfo.phoneNumber.trim() !== "" &&
    contactInfo.email.trim() !== "" ;
  return (
    <div className="mt-3">
      <div className="containerRight">
        <div className="rightHeading">
          <div className="d-flex BackHeading">
            <button onClick={prevStep}>
              <img src={backButton} alt="backButton" />
            </button>
            <h3>What Contact Details Do You Want To Show For Restaurant?</h3>
          </div>
          <p>
            Help Customers get in touch by including this info on your
            Restaurant listing.
          </p>
        </div>
        <div className="overflowHeight">
          <div className="col-sm-9 mb-3">
            <div className="row">
              <div className="col-3" style={{ position: "relative" }}>
                <input
                  type="text"
                  name="countryCode"
                  placeholder="+91"
                  className={`form-control ${
                    errors.countryCode ? "input-error" : ""
                  } ${shake ? "shake" : ""} ${
                    focusedField === "countryCode" ? "input-focus" : ""
                  }`}
                  value={contactInfo.countryCode}
                  onFocus={() => handleFocus("countryCode")}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  readOnly
                />
                {errors.countryCode && (
                  <span className="error-message">
                    Country code is required
                  </span>
                )}
              </div>
              <div
                className="col-9"
                style={{ position: "relative", paddingLeft: "0" }}
              >
                {(focusedField === "phoneNumber" ||
                  contactInfo.phoneNumber) && (
                  <label className="HeadBussiness">Phone Number*</label>
                )}
                <input
                  type="text"
                  name="phoneNumber"
                  maxLength={10}
                  placeholder="Phone Number*"
                  className={`form-control ${
                    errors.phoneNumber ? "input-error" : ""
                  } ${shake ? "shake" : ""} ${
                    focusedField === "phoneNumber" ? "input-focus" : ""
                  }`}
                  value={contactInfo.phoneNumber}
                  onFocus={() => handleFocus("phoneNumber")}
                  onBlur={handleBlur}
                  onChange={handleMobilevalidation}
                />
                {errors.phoneNumber && (
                  <span className="error-message">
                    Phone number is required
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="col-sm-9" style={{ position: "relative" }}>
            {(focusedField === "email" || contactInfo.email) && (
              <label className="HeadBussiness">Restaurant Email ID*</label>
            )}
            <input
              type="text"
              name="email"
              placeholder="Restaurant Email ID*"
              className={`form-control ${errors.email ? "input-error" : ""} ${
                shake ? "shake" : ""
              } ${focusedField === "email" ? "input-focus" : ""}`}
              value={contactInfo.email}
              onFocus={() => handleFocus("email")}
              onBlur={handleBlur}
              onChange={handleEmailChange}
            />
            {errors.email && (
              <span className="error-message">Email is required</span>
            )}
          </div>

          <div
            className={`fixNextButton  ${
              isFormValid ? "nonEmptyBttn" : " emptyFiled"
            }`}
            style={{ marginTop: "0px" }}
          >
            <div className="container-lg d-flex justify-content-end">
              <button onClick={handleNextStep}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboadingstep2;

import React, { useState, useEffect } from "react";
import Header from "./Header";
import Mobilefotter from "./Mobilefotter.js";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "./Css/Home.css";
import { Link } from "react-router-dom";
import homebg from "../Assets/homebg.png";
import SearchBar from "./SearchBar";
import Collections from "./Collections";
import star from "../Assets/star.svg";
import rigthArrow from "../Assets/right_svg_button.svg";
import leftArrow from "../Assets/left_svg_button.svg";
import str2 from "../Assets/5stars.svg";
import str1 from "../Assets/singleStar.svg";
import tick from "../Assets/tick.svg";
import percentage from "../Assets/percentage.svg";
import Heart from "../Assets/heart.svg";
import HeartRed from "../Assets/HeartRed.svg";
import DownloadApp from "./DownloadApp";
import AreYouAVenueOwner from "./AreYouAVenueOwner";
import Shimmer from "./Shimmer.js";
import errorLocation from "../Assets/errorLocation.svg";
import CommingSoon from "../Assets/tablecomming.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "./Footer";
import $ from "jquery";
import {
  server_post_data,
  get_landingpage_webapp,
  save_favourite,
  APL_LINK,
} from "../ServiceConnection/serviceconnection.js";
import {
  handleLinkClick,
  inputdateformateChange,
  handleError,
} from "../CommonJquery/CommonJquery.js";
import { retrieveData } from "../LocalConnection/LocalConnection.js";
import Landmark from "./Landmark.js";
import Cuisine from "./Cuisine.js";

// Popler Img
import MpnagrImg from "../Assets/MpnagrImg.jpg";
import LalghatiImg from "../Assets/LalghatiImg.png";
import kolarImg from "../Assets/kolarImg.png";
import NarmadaPuramRoad from "../Assets/NarmadaPuramRoad.png";
import oldBhopal from "../Assets/oldBhopal.png";
import karodImg from "../Assets/karodImg.png";
import AiimsImg from "../Assets/AiimsImg.png";
import ShyamlaHils from "../Assets/ShyamlaHils.png";
import gulmohar from "../Assets/gulmohar.png";
import bhelimg from "../Assets/bhelimg.png";
import bawariyaKala from "../Assets/bawariyaKala.png";
import trilangaImg from "../Assets/trilangaImg.png";
import AyodhyaByPassImg from "../Assets/AyodhyaByPassImg.png";
import AshokaGardenImg from "../Assets/AshokaGardenImg.png";
import misordImg from "../Assets/misordImg.png";
import AirPortBpl from "../Assets/AirPortBpl.png";
import bairagadImg from "../Assets/bairagadImg.png";
import NeelBadImg from "../Assets/NeelBadImg.png";
import RatibadImg from "../Assets/RatibadImg.png";
import katraHils from "../Assets/katraHils.png";
import raisenImg from "../Assets/raisenImg.png";
import areracolonyimg from "../Assets/areracolonyimg.png";
import newMarktImg from "../Assets/newMarktImg.png";
import upperlakeImg from "../Assets/upperlakeImg.png";
import SehoreImg from "../Assets/SehoreImg.png";
import vipBhopl from "../Assets/vipBhopl.png";
import kerwaDam from "../Assets/kerwaDam.png";
import chunabattiImg from "../Assets/chunabattiImg.png";
import Itparkimg from "../Assets/Itparkimg.png";
import sahpuraImg from "../Assets/sahpuraImg.png";
import goutmNagar from "../Assets/goutmNagar.png";
import MinalImg from "../Assets/MinalImg.png";
import dasNobhopal from "../Assets/dasNobhopal.png";
import bittnMarket from "../Assets/bittnMarket.png";

let customer_id = "0";
function Home() {
  customer_id = retrieveData("customer_id");
  const [SEOloop, setSEOloop] = useState([]);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [blogs, Setblogs] = useState([]);
  const [city, setCity] = useState("");
  const [restaurantByPopular, setRestaurantByPopular] = useState([]);
  const [restaurantByTranding, setRestaurantByTranding] = useState([]);
  const [LandmarkData, setLandmarkData] = useState([]);
  const [CuisineData, setCuisineData] = useState([]);
  const [restaurantByNew, setRestaurantByNew] = useState([]);
  const [ImageLink, setImageLink] = useState("");
  const [HeartImg, setHeartImages] = useState([]);
  const [collection, setCollection] = useState([]);
  // Toggle the like state for a specific venue

  //get data
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    await server_post_data(get_landingpage_webapp, null)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          Setblogs(Response.data.message.blog_active_data);
          setSEOloop(Response.data.message.seo_loop);
          setCollection(Response.data.message.category_countsss);
          setCity(Response.data.message.city_name);
          setImageLink(Response.data.message.image_link);
          setHeartImages(Response.data.message.data_favourite_data);
          setLandmarkData(Response.data.message.landmark_data);
          setCuisineData(Response.data.message.cuisine_data);
          setRestaurantByPopular(
            Response.data.message.all_restaurants.filter((restaurant) => {
              return restaurant.restaurant_type === 1;
            })
          );
          setRestaurantByTranding(
            Response.data.message.all_restaurants.filter((restaurant) => {
              return restaurant.restaurant_type === 2;
            })
          );
          setRestaurantByNew(
            Response.data.message.all_restaurants.filter((restaurant) => {
              return restaurant.restaurant_type === 3;
            })
          );
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const match_and_return_seo_link = (v_id) => {
    let data_seo_link_final = "/restro/restro_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const match_and_return_seo_blog_link = (v_id) => {
    let data_seo_link_final = "/blog/blog_detail/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const match_and_return_filter_link = (v_id) => {
    let data_seo_link_final = "/restro/by_filter/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };

  const text_short = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };
  // pagination of popular venues City
  const [currentPaginationPagePopular, setCurrentPaginationPagePopular] =
    useState(1);
  const [currentPaginationPageLocalArea, setCurrentPaginationPageLocalArea] =
    useState(1);
  const itemsPerPage = 4;
  const itemslandmark = 3;

  const totalPaginationPagesPopular = Math.ceil(
    restaurantByPopular && restaurantByPopular.length / itemsPerPage
  );
  const totalPaginationPagesLocalArea = Math.ceil(
    LandmarkData.length / itemslandmark
  );
  const paginatedLocalAreaData = LandmarkData.slice(
    (currentPaginationPageLocalArea - 1) * itemslandmark,
    currentPaginationPageLocalArea * itemslandmark
  );
  const handleNextPageLocalArea = () => {
    setCurrentPaginationPageLocalArea((prevPage) =>
      Math.min(prevPage + 1, totalPaginationPagesLocalArea)
    );
  };

  // console.log("LandmarkData   ", LandmarkData);
  const handlePreviousPageLocalArea = () => {
    setCurrentPaginationPageLocalArea((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPagePopular = () => {
    setCurrentPaginationPagePopular((prevPage) =>
      Math.min(prevPage + 1, totalPaginationPagesPopular)
    );
  };

  const handlePreviousPagePopular = () => {
    setCurrentPaginationPagePopular((prevPage) => Math.max(prevPage - 1, 1));
  };

  const indexOfLastItemPopular = currentPaginationPagePopular * itemsPerPage;
  const indexOfFirstItemPopular = indexOfLastItemPopular - itemsPerPage;
  const currentPaginationItemsPopular =
    restaurantByPopular &&
    restaurantByPopular.slice(indexOfFirstItemPopular, indexOfLastItemPopular);
  const currentPaginationItemsMobilePopular =
    restaurantByPopular &&
    restaurantByPopular.slice(indexOfFirstItemPopular, indexOfLastItemPopular);
  // pagination of popular venues City

  // pagination of Tranding venues City
  const [currentPaginationPageTranding, setCurrentPaginationPageTranding] =
    useState(1);

  const totalPaginationPagesTranding = Math.ceil(
    restaurantByTranding && restaurantByTranding.length / itemsPerPage
  );

  const handleNextPageTranding = () => {
    setCurrentPaginationPageTranding((prevPage) =>
      Math.min(prevPage + 1, totalPaginationPagesTranding)
    );
  };

  const handlePreviousPageTranding = () => {
    setCurrentPaginationPageTranding((prevPage) => Math.max(prevPage - 1, 1));
  };

  const indexOfLastItemTranding = currentPaginationPageTranding * itemsPerPage;
  const indexOfFirstItemTranding = indexOfLastItemTranding - itemsPerPage;
  const currentPaginationItemsTranding =
    restaurantByTranding &&
    restaurantByTranding.slice(
      indexOfFirstItemTranding,
      indexOfLastItemTranding
    );
  const currentPaginationItemsMobileTranding =
    restaurantByTranding &&
    restaurantByTranding.slice(
      indexOfFirstItemTranding,
      indexOfLastItemTranding
    );
  // pagination of Tranding venues City

  // pagination of New venues City
  const [currentPaginationPageNew, setCurrentPaginationPageNew] = useState(1);

  const totalPaginationPagesNew = Math.ceil(
    restaurantByNew && restaurantByNew.length / itemsPerPage
  );

  const handleNextPageNew = () => {
    setCurrentPaginationPageNew((prevPage) =>
      Math.min(prevPage + 1, totalPaginationPagesNew)
    );
  };

  const handlePreviousPageNew = () => {
    setCurrentPaginationPageNew((prevPage) => Math.max(prevPage - 1, 1));
  };

  const indexOfLastItemNew = currentPaginationPageNew * itemsPerPage;
  const indexOfFirstItemNew = indexOfLastItemNew - itemsPerPage;
  const currentPaginationItemsNew =
    restaurantByNew &&
    restaurantByNew.slice(indexOfFirstItemNew, indexOfLastItemNew);
  const currentPaginationItemsMobileNew =
    restaurantByNew &&
    restaurantByNew.slice(indexOfFirstItemNew, indexOfLastItemNew);
  // pagination of New venues City

  useEffect(() => {
    master_data_get();
  }, []);

  // $("#reload_page_data").on("customEvent", function () {
  //   master_data_get();
  // });

  const handleHeartClick = (venueId) => {
    if (customer_id !== "0") {
      handleSaveChangesdynamic(venueId);
    } else {
      var event = new CustomEvent("customEvent");
      document.getElementById("login_check_jquery").dispatchEvent(event);
    }
  };

  const isFavorite = (venueId) => {
    return HeartImg.some((fav) => fav.restaurant_id === venueId);
  };

  const handleSaveChangesdynamic = async (venueId) => {
    const form_data = new FormData();
    form_data.append("venue_id", venueId);
    await server_post_data(save_favourite, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          try {
            const updatedFavorites = HeartImg.some(
              (fav) => fav.restaurant_id === venueId
            )
              ? HeartImg.filter((fav) => fav.restaurant_id !== venueId) // Remove from favorites
              : [...HeartImg, { restaurant_id: venueId }];

            // Update state with the new list of favorites
            setHeartImages(updatedFavorites);
          } catch (error) {
            console.error("Error updating favorite status:", error);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //mobile condition
  const [isMobile, setIsMobile] = useState(window.innerWidth < 576);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const VenueCardDesign = ({ venue }) => {
    let click_by_link = match_and_return_seo_link(venue.primary_id);
    return (
      <Link
        style={{
          textDecoration: "none",
          cursor: "pointer !important",
        }}
      >
        <div className="popularVenues_venue_container">
          <div className="venue_image_holder">
            {/* <img
              src={APL_LINK + ImageLink + venue.restaurant_image}
              alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
              onClick={() => handleLinkClick(click_by_link)}
            /> */}

            <LazyLoadImage
              src={APL_LINK + ImageLink + venue.restaurant_image}
              alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
              onClick={() => handleLinkClick(click_by_link)}
            />

            <button
              className="heartBttnn"
              onClick={() => handleHeartClick(venue.primary_id)}
            >
              <img
                src={isFavorite(venue.primary_id) ? HeartRed : Heart}
                alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                className="HeartHomeIcon"
              />
            </button>
          </div>

          <div
            className="venueDetailCOntainer "
            onClick={() => handleLinkClick(click_by_link)}
          >
            <div className="venue_category_div">
              <span className="venue_category_titles">
                <p>{venue.cuisine}&nbsp;</p>
              </span>
              <div className="rating_greenDiv">
                <p>{venue.rating}</p>
                <img
                  src={star}
                  alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                />
              </div>
            </div>
            <div className="venue_address_wrapper">
              <h6 className="ellipsis venue_address_heading">
                {venue.restaurant_name}&nbsp;
              </h6>
              <span className="ellipsis address_restaurant_home ">
                {venue.restaurant_full_adrress}&nbsp;
              </span>
              <span className="venue_capacity_wrapper">
                <p>
                  {venue.first_money_icon} {venue.restaurant_price}{" "}
                  {venue.last_money_icon} Average Price&nbsp;
                </p>
              </span>
              <div className="venue_category_div ">
                <span className="venue_category_titles venue_discount_wrapper">
                  <p>-{venue.discount_upto}%&nbsp;</p>
                </span>
                {parseInt(venue.distance_km) > 0 &&
                  parseInt(venue.distance_km) < 30 && (
                    <div className=" first_row_black_section_carousel">
                      <p className="color_red">{venue.distance_km} km</p>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    );
  };
  const match_and_return_seo_linkLocal = (v_id) => {
    let data_seo_link_final = "/restro/by_landmark/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    // nextArrow: <NextArrow />,
    // prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const handleImageError = (e) => {
    e.target.src = errorLocation;
  };
  const match_and_return_seo_link_LocalArera = (v_id) => {
    let data_seo_link_final = "/restro/by_landmark/" + v_id;
    let data_seo_link = data_seo_link_final;
    if (SEOloop) {
      const matchedItem = SEOloop.find((data) => {
        return data_seo_link === data.call_function_name;
      });

      if (matchedItem) {
        data_seo_link_final = matchedItem.pretty_function_name;
      }
    }
    return data_seo_link_final;
  };
  const PopulerLocality = [
    {
      image_name: MpnagrImg,
      landmark_name: "MP Nagar",
      no_of_restaurant: 10,
    },
    {
      image_name: LalghatiImg,
      landmark_name: "Lalghati",
      no_of_restaurant: 15,
    },
    {
      image_name: kolarImg,
      landmark_name: "Kolar",
      no_of_restaurant: 8,
    },
    {
      image_name: NarmadaPuramRoad,
      landmark_name: "Narmadapuram Road",
      no_of_restaurant: 8,
    },
    {
      image_name: oldBhopal,
      landmark_name: "Old Bhopal",
      no_of_restaurant: 8,
    },
    {
      image_name: karodImg,
      landmark_name: "Karond",
      no_of_restaurant: 8,
    },
    {
      image_name: AiimsImg,
      landmark_name: "AIIMS",
      no_of_restaurant: 8,
    },
    {
      image_name: ShyamlaHils,
      landmark_name: "Shyamla hills",
      no_of_restaurant: 8,
    },
    {
      image_name: gulmohar,
      landmark_name: "Gulmohar",
      no_of_restaurant: 8,
    },
    {
      image_name: bhelimg,
      landmark_name: "BHEL",
      no_of_restaurant: 8,
    },
    {
      image_name: bawariyaKala,
      landmark_name: "Bawadiya Kala",
      no_of_restaurant: 8,
    },
    {
      image_name: trilangaImg,
      landmark_name: "Trilanga",
      no_of_restaurant: 8,
    },
    {
      image_name: AyodhyaByPassImg,
      landmark_name: "Ayodhya By Pass",
      no_of_restaurant: 8,
    },
    {
      image_name: AshokaGardenImg,
      landmark_name: "Ashoka Garden",
      no_of_restaurant: 8,
    },
    {
      image_name: misordImg,
      landmark_name: "Misrod",
      no_of_restaurant: 8,
    },
    {
      image_name: AirPortBpl,
      landmark_name: "Airport",
      no_of_restaurant: 8,
    },
    {
      image_name: bairagadImg,
      landmark_name: "Bairaghad",
      no_of_restaurant: 8,
    },
    {
      image_name: NeelBadImg,
      landmark_name: "Neelbad",
      no_of_restaurant: 8,
    },
    {
      image_name: RatibadImg,
      landmark_name: "Ratibad",
      no_of_restaurant: 8,
    },
    {
      image_name: katraHils,
      landmark_name: "Katara Hills",
      no_of_restaurant: 8,
    },
    {
      image_name: raisenImg,
      landmark_name: "Raisen road",
      no_of_restaurant: 8,
    },
    {
      image_name: areracolonyimg,
      landmark_name: "Arera Colony",
      no_of_restaurant: 8,
    },
    {
      image_name: newMarktImg,
      landmark_name: "New Market",
      no_of_restaurant: 8,
    },
    {
      image_name: upperlakeImg,
      landmark_name: "Upper lake",
      no_of_restaurant: 8,
    },
    {
      image_name: SehoreImg,
      landmark_name: "Sehore Road",
      no_of_restaurant: 8,
    },
    {
      image_name: vipBhopl,
      landmark_name: "VIP Road",
      no_of_restaurant: 8,
    },
    {
      image_name: kerwaDam,
      landmark_name: "Kerwa Dam",
      no_of_restaurant: 8,
    },
    {
      image_name: dasNobhopal,
      landmark_name: "10. NO Market",
      no_of_restaurant: 8,
    },
    {
      image_name: bittnMarket,
      landmark_name: "Bittan Market",
      no_of_restaurant: 8,
    },
    {
      image_name: Itparkimg,
      landmark_name: "IT Park",
      no_of_restaurant: 8,
    },
    {
      image_name: chunabattiImg,
      landmark_name: "Chunabhatti",
      no_of_restaurant: 8,
    },
    {
      image_name: sahpuraImg,
      landmark_name: "Sahapura",
      no_of_restaurant: 8,
    },
    {
      image_name: goutmNagar,
      landmark_name: "Gautam Nagar",
      no_of_restaurant: 8,
    },
    {
      image_name: MinalImg,
      landmark_name: "Minal Residency",
      no_of_restaurant: 8,
    },
  ];

  return (
    <>
      <Header />

      {showLoaderAdmin ? (
        <Shimmer />
      ) : (
        <>
          {LandmarkData.length <= 0 &&
          restaurantByPopular.length <= 0 &&
          restaurantByTranding.length <= 0 ? (
            <div className="comming_soon">
              <div className="comming_soon_wrapper">
                <img
                  className="commingImg"
                  src={CommingSoon}
                  alt="Dfoodo Reserve Table"
                />
                <div>
                  <h2 className="comingHead">Coming Soon!</h2>
                  <p>
                    A new way to reserve your table effortlessly is almost
                    here! Stay tuned for an exclusive dining experience.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div style={{ overflow: "hidden" }}>
              <div>
                <section className="heroSection ">
                  <div
                    className={`row ${
                      isMobile ? "m-0" : "container-lg m-auto"
                    } d-none d-md-flex`}
                  >
                    <div className="heroSection_wrapper p-lg-0 col-lg-12 col-12">
                      <img
                        src={homebg}
                        alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                        id="homeBG"
                      />
                      <div className="searchBar_container_homeScreen">
                        <div className="Heading_herosection">
                          <h1>
                            Effortless Table Reservations – Dine Your Way with
                            Dfoodo
                          </h1>
                        </div>
                        <div className="serachBxx">
                          <div className="searchBarInHeroSection w-100">
                            <SearchBar />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`row ${
                      isMobile ? "m-0" : "container-lg m-auto"
                    } d-md-none`}
                  >
                    <SearchBar />
                  </div>
                </section>
                {/**----------Update Design ----*/}
                <div className="container-lg padding991">
                  <div className="col-xl-10 col-lg-11 m-auto">
                    <div className="container-lg Local">
                      <div className="row g-3 populerLocalitySect">
                        <div className="popularVenues_heading_container marginBttm">
                          <div className="collections_sections_heading">
                            <h3>Popular Locality Near {city}</h3>

                            <h6>
                              Find top restaurants, cafes, and pubs tailored to
                              your taste in {city}
                            </h6>
                          </div>
                        </div>

                        <div className="populerlocationNEwDesign">
                          {LandmarkData.map((location, index) => (
                            <div
                              className="col-sm-3 col-md-1 widthPolirClass"
                              key={index}
                            >
                              <div
                                className="PopLodata"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleLinkClick(
                                    match_and_return_seo_link_LocalArera(
                                      location.primary_id
                                    )
                                  )
                                }
                              >
                                <LazyLoadImage
                                  src={`${
                                    APL_LINK + ImageLink + location.image_name
                                  }`}
                                  // src={location.image_name}
                                  alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                  effect="blur"
                                  className="lazy-image PopulerImg"
                                  title="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                  onError={handleImageError}
                                />
                                <p> {location.landmark_name}</p>
                                <label>
                                  {" "}
                                  {location.no_of_restaurant} places
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container-lg padding991">
                  <div className="col-xl-10 col-lg-11 m-auto">
                    {/* {LandmarkData && LandmarkData.length > 0 && (
                  <div className="container-lg Local">
                    <section className="collections_section">
                      <div className="collections_sections_heading">
                        <h3>Local Area</h3>
                        <h6>
                          Explore curated lists of top restaurantadadadads, cafes,
                          pubs, and bars in {city},ada based on Areaadadadad
                        </h6>
                      </div>
                      <div>
                        <Landmark
                          data={LandmarkData}
                          SEOloop={SEOloop}
                          ImageLink={ImageLink}
                        />
                      </div>
                    </section>
                  </div>
                )} */}

                    {collection && collection.length > 0 && (
                      <section className="collections_section">
                        {/* <div className="container-lg"> */}
                        <div className="collections_sections_heading">
                          <h3>Collections</h3>
                          <h6>
                            Explore curated lists of top restaurants, cafes,
                            pubs, and bars in {city}, based on trends
                          </h6>
                        </div>
                        {/* </div> */}
                        <Collections
                          data={collection}
                          SEOloop={SEOloop}
                          ImageLink={ImageLink}
                        />
                      </section>
                    )}

                    {restaurantByPopular && restaurantByPopular.length > 0 && (
                      <section>
                        <div className="popularVenues_section">
                          <div className="popularVenues_heading_container">
                            <h3>Popular Restaurants in {city} </h3>
                            <span className="seAll_span">
                              {!isMobile && (
                                <div className="pagination_controls">
                                  <button
                                    onClick={handlePreviousPagePopular}
                                    disabled={
                                      currentPaginationPagePopular === 1
                                    }
                                  >
                                    <img
                                      src={leftArrow}
                                      alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                    />
                                  </button>
                                  <button
                                    onClick={handleNextPagePopular}
                                    disabled={
                                      currentPaginationPagePopular ===
                                      totalPaginationPagesPopular
                                    }
                                  >
                                    <img
                                      src={rigthArrow}
                                      alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                    />
                                  </button>
                                </div>
                              )}
                              {isMobile && (
                                <div className="pagination_controls">
                                  <Link
                                    onClick={() =>
                                      handleLinkClick(
                                        match_and_return_filter_link(
                                          "Popularity"
                                        )
                                      )
                                    }
                                  >
                                    View More
                                  </Link>
                                </div>
                              )}
                            </span>
                          </div>
                          <div className="popularVenues">
                            <div className="venue_cards_container row mx-0 mt-1">
                              {!isMobile &&
                                currentPaginationItemsPopular &&
                                currentPaginationItemsPopular.length > 0 &&
                                currentPaginationItemsPopular.map(
                                  (venue, index) => (
                                    <div
                                      className="col-xl-3 col-md-4 col-sm-6 padding5p"
                                      key={index}
                                    >
                                      <VenueCardDesign venue={venue} />
                                    </div>
                                  )
                                )}
                              <div className="horizontal-scroll-container">
                                {isMobile &&
                                  currentPaginationItemsMobilePopular &&
                                  currentPaginationItemsMobilePopular.length >
                                    0 &&
                                  currentPaginationItemsMobilePopular.map(
                                    (venue, index) => (
                                      <div
                                        className="col-lg-3 col-md-4 col-sm-6"
                                        key={index}
                                      >
                                        <VenueCardDesign venue={venue} />
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    )}
                    {restaurantByTranding &&
                      restaurantByTranding.length > 0 && (
                        <section>
                          <div className="popularVenues_section">
                            {/* <div className="container-lg"> */}
                            <div className="popularVenues_heading_container">
                              <h3>Highly Recommanded Restaurants in {city} </h3>
                              <span className="seAll_span">
                                {!isMobile && (
                                  <div className="pagination_controls">
                                    <button
                                      onClick={handlePreviousPageTranding}
                                      disabled={
                                        currentPaginationPageTranding === 1
                                      }
                                    >
                                      <img
                                        src={leftArrow}
                                        alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                      />
                                    </button>
                                    <button
                                      onClick={handleNextPageTranding}
                                      disabled={
                                        currentPaginationPageTranding ===
                                        totalPaginationPagesTranding
                                      }
                                    >
                                      <img
                                        src={rigthArrow}
                                        alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                      />
                                    </button>
                                  </div>
                                )}
                                {isMobile && (
                                  <div className="pagination_controls">
                                    <Link
                                      onClick={() =>
                                        handleLinkClick(
                                          match_and_return_filter_link(
                                            "HighRecommanded"
                                          )
                                        )
                                      }
                                    >
                                      View More
                                    </Link>
                                  </div>
                                )}
                              </span>
                            </div>
                            <div className="popularVenues">
                              <div className="venue_cards_container row mx-0 mt-1">
                                {!isMobile &&
                                  currentPaginationItemsTranding &&
                                  currentPaginationItemsTranding.length > 0 &&
                                  currentPaginationItemsTranding.map(
                                    (venue, index) => (
                                      <div
                                        className="col-xl-3 col-md-4 col-sm-6 padding5p"
                                        key={index}
                                      >
                                        <VenueCardDesign venue={venue} />
                                      </div>
                                    )
                                  )}
                                <div className="horizontal-scroll-container">
                                  {isMobile &&
                                    currentPaginationItemsMobileTranding &&
                                    currentPaginationItemsMobileTranding.length >
                                      0 &&
                                    currentPaginationItemsMobileTranding.map(
                                      (venue, index) => (
                                        <div
                                          className="col-lg-3 col-md-4 col-sm-6"
                                          key={index}
                                        >
                                          <VenueCardDesign venue={venue} />
                                        </div>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </section>
                      )}

                    {CuisineData && CuisineData.length > 0 && (
                      <section className="collections_section">
                        {/* <div className="container-lg"> */}
                        <div className="collections_sections_heading">
                          <h3>Cuisine</h3>
                          <h6>
                            Explore curated lists of top restaurants, cafes,
                            pubs, and bars in {city}, based on cuisine
                          </h6>
                        </div>
                        {/* </div> */}
                        <div>
                          <Cuisine
                            data={CuisineData}
                            SEOloop={SEOloop}
                            ImageLink={ImageLink}
                          />
                        </div>
                      </section>
                    )}

                    {restaurantByNew && restaurantByNew.length > 0 && (
                      <section>
                        <div className="popularVenues_section">
                          {/* <div className="container-lg"> */}
                          <div className="popularVenues_heading_container">
                            <h3>New Launch In {city}</h3>
                            <span className="seAll_span">
                              {!isMobile && (
                                <div className="pagination_controls">
                                  <button
                                    onClick={handlePreviousPageNew}
                                    disabled={currentPaginationPageNew === 1}
                                  >
                                    <img
                                      src={leftArrow}
                                      alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                    />
                                  </button>
                                  <button
                                    onClick={handleNextPageNew}
                                    disabled={
                                      currentPaginationPageNew ===
                                      totalPaginationPagesNew
                                    }
                                  >
                                    <img
                                      src={rigthArrow}
                                      alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                    />
                                  </button>
                                </div>
                              )}
                              {isMobile && (
                                <div className="pagination_controls">
                                  <Link
                                    onClick={() =>
                                      handleLinkClick(
                                        match_and_return_filter_link(
                                          "NewDfoodo"
                                        )
                                      )
                                    }
                                  >
                                    View More
                                  </Link>
                                </div>
                              )}
                            </span>
                          </div>
                          <div className="popularVenues">
                            <div className="venue_cards_container row mx-0 mt-1">
                              {!isMobile &&
                                currentPaginationItemsNew &&
                                currentPaginationItemsNew.length > 0 &&
                                currentPaginationItemsNew.map(
                                  (venue, index) => (
                                    <div
                                      className="col-xl-3 col-md-4 col-sm-6 padding5p"
                                      key={index}
                                    >
                                      <VenueCardDesign venue={venue} />
                                    </div>
                                  )
                                )}
                              <div className="horizontal-scroll-container">
                                {isMobile &&
                                  currentPaginationItemsMobileNew &&
                                  currentPaginationItemsMobileNew.length > 0 &&
                                  currentPaginationItemsMobileNew.map(
                                    (venue, index) => (
                                      <div
                                        className="col-lg-3 col-md-4 col-sm-6"
                                        key={index}
                                      >
                                        <VenueCardDesign venue={venue} />
                                      </div>
                                    )
                                  )}
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      </section>
                    )}
                    {/* how does it works section */}
                    <section className="how_does_it_work_section">
                      <div className="how_does_it_work_wrapper">
                        {/* <div className="container-lg"> */}
                        <div className="how_it_works_content">
                          <div className="how_it_works_content_heading">
                            <h5>How Does It Work?</h5>
                          </div>
                          <div className="row m-0">
                            <div className="col-md-3">
                              <div className="sigle_star_section">
                                <img
                                  src={str1}
                                  alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                />
                                <h6>Discover Top Restaurants</h6>
                                <p>
                                  Browse a curated selection of the best dining
                                  spots that match your taste, mood, and
                                  occasion.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="multi_star_section">
                                <div
                                  style={{
                                    height: "65px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <img
                                    style={{ marginBottom: "0" }}
                                    src={str2}
                                    alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                    id="multiStars"
                                  />
                                </div>
                                <h6>Authentic Reviews, Trusted Choices</h6>
                                <p>
                                  Rely on genuine feedback from our community to
                                  make the perfect dining decisions.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="percentage_section">
                                <img
                                  src={percentage}
                                  alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                />
                                <h6>Flexible Deals for Every Moment</h6>
                                <p>
                                  Enjoy exclusive discounts and special offers
                                  tailored to off-peak hours or happy occasions.
                                </p>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="sigle_star_section">
                                <img
                                  src={tick}
                                  alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                />
                                <h6>Seamless Reservations</h6>
                                <p>
                                  Book your table instantly, anytime, anywhere –
                                  simple, efficient, and completely free!
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </section>

                    {/* discover more great venues */}
                    <section>
                      <div className="discover_more_venues_section">
                        {/* <div className="container"> */}
                        <div className="row m-0">
                          <div className="popularVenues_heading_container">
                            <h2>Discover more great restaurant</h2>
                            <span className="seAll_span">
                              <Link to="/blogs">
                                <p>Explore All Blog Posts</p>
                              </Link>
                            </span>
                          </div>
                          {blogs.slice(0, 2).map((blog, index) => (
                            <div
                              className="col-lg-4 col-md-6 mb-3 mobilePddng"
                              key={index}
                            >
                              <div className="discoverMore_container">
                                <Link
                                  onClick={() =>
                                    handleLinkClick(
                                      match_and_return_seo_blog_link(
                                        blog.primary_id
                                      )
                                    )
                                  }
                                  style={{
                                    textDecoration: "none",
                                    color: "var(--text-black)",
                                  }}
                                >
                                  <img
                                    className="imageblog_size"
                                    src={blog.image_name}
                                    alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                  />
                                  <div className="discoverMore_containerText">
                                    <h6>{text_short(blog.title_name, 34)}</h6>
                                    <p>{text_short(blog.tag_line, 50)}</p>
                                    <p
                                      style={{
                                        color: "var(--primary-color)",
                                        paddingBottom: "0.5rem",
                                        margin: "0",
                                      }}
                                    >
                                      {inputdateformateChange(blog.entry_date)}
                                    </p>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          ))}
                          <div className="col-lg-4 d-none d-lg-block mb-3">
                            <div className="verticle_container_discoverMore">
                              {blogs.slice(0, 4).map((blog, index) => (
                                <Link
                                  onClick={() =>
                                    handleLinkClick(
                                      match_and_return_seo_blog_link(
                                        blog.primary_id
                                      )
                                    )
                                  }
                                  key={index}
                                  style={{
                                    textDecoration: "none",
                                    color: "var(--text-black)",
                                  }}
                                >
                                  <div
                                    className="smaller_container_discoverMore"
                                    key={index}
                                  >
                                    <img
                                      src={blog.image_name}
                                      alt="Dfoodo - Reserve Tables & Discover Top Restaurants Near You"
                                    />
                                    <div className="heading_discoverMore">
                                      <h6>{blog.title_name}</h6>
                                      <p>
                                        {inputdateformateChange(
                                          blog.entry_date
                                        )}
                                      </p>
                                      <hr width="100%" />
                                    </div>
                                  </div>
                                </Link>
                              ))}
                            </div>
                          </div>
                        </div>
                        {/* </div> */}
                      </div>
                    </section>
                    <section>
                      <DownloadApp />
                    </section>
                    <section>
                      <AreYouAVenueOwner />
                    </section>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      <Mobilefotter />
      <Footer />
    </>
  );
}

export default Home;

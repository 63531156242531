import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { retrieveData } from "../LocalConnection/LocalConnection";
import { handleError } from "../CommonJquery/CommonJquery";
import {
  server_post_data,
  save_chargebee_subscription,
} from "../ServiceConnection/serviceconnection";
import { useNavigate } from "react-router-dom";
let default_restaurant_id_staff = "0";
const SuccessPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showLoader, setshowLoader] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState(null);
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");
  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const subscriptionId = urlParams.get("sub_id");
    const customerId = urlParams.get("cus_id");
    const startDate = urlParams.get("start_date");
    const paymentStatus = urlParams.get("status");
    console.log("Subscription ID:", subscriptionId);
    console.log("Customer ID:", customerId);
    console.log("Start Date:", startDate);
    console.log("Payment Status:", paymentStatus);
    saveSubsData(customerId, subscriptionId, startDate);
    setPaymentDetails({ subscriptionId, customerId, startDate, paymentStatus });
  }, [location]);

  const saveSubsData = async (customerId, subscriptionId, startDate) => {
    console.log(customerId, subscriptionId, startDate);
    setshowLoader(true);
    const form_data = new FormData();

    form_data.append("restaurant_id", default_restaurant_id_staff);
    form_data.append("order_id", customerId);
    const currentDate = new Date().toISOString().split("T")[0];
    form_data.append("subscription_start_date", currentDate);
    form_data.append("transaction_id", subscriptionId);

    await server_post_data(save_chargebee_subscription, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          navigate("/registerMyVenue");
          console.log("Hello Next Step");
        }
        setshowLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setshowLoader(false);
      });
  };

  return (
    <div>
      <h2>Payment Status</h2>
      {paymentDetails ? (
        <div>
          <p>
            <strong>Subscription ID:</strong> {paymentDetails.subscriptionId}
          </p>
          <p>
            <strong>Customer ID:</strong> {paymentDetails.customerId}
          </p>
          <p>
            <strong>Start Date:</strong> {paymentDetails.startDate}
          </p>
          <p>
            <strong>Payment Status:</strong> {paymentDetails.paymentStatus}
          </p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SuccessPage;

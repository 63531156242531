import React, { useState, useEffect } from "react";
import backButton from "../Assets/backButton.svg";
import "./Css/Newonboading.css";
import { retrieveData } from "../LocalConnection/LocalConnection";
import {
  handleError,
  handleNumbersChange,
  handleIaphabetnumberChange,
} from "../CommonJquery/CommonJquery";

import {
  sava_onboarding_form,
  get_last_resturant_data_save,
  server_post_data,
  fssai_verification_combined,
  gst_verification_combined,
} from "../ServiceConnection/serviceconnection";

let staff_id = "0";
let default_restaurant_id_staff = "0";

const Onboadingstep6 = ({ nextStep, prevStep }) => {
  staff_id = retrieveData("staff_id");
  default_restaurant_id_staff = retrieveData("default_restaurant_id_staff");

  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [restaurantData, setrestaurantData] = useState([]);
  const [fssaiNumber, setFssaiNumber] = useState("");
  const [fssaiVerified, setFssaiVerified] = useState(null);
  const [fssaiActive, setFssaiActive] = useState("");
  const [gstVerified, setGstVerified] = useState(null);
  const [gstActive, setGstActive] = useState("");
  const [gstinNumber, setGstinNumber] = useState("");
  const [errors, setErrors] = useState({ fssai: false, gstin: false });
  const [isFormValid, setIsFormValid] = useState(false);

  // Separate loader states for each verification
  const [isFssaiLoading, setIsFssaiLoading] = useState(false);
  const [isGstLoading, setIsGstLoading] = useState(false);

  const handleFssaiChange = (event) => {
    handleNumbersChange(event);
    setFssaiNumber(event.target.value);
    if (event.target.value) {
      setErrors((prev) => ({ ...prev, fssai: false }));
    }
  };

  const handleGstinChange = (event) => {
    handleIaphabetnumberChange(event);
    setGstinNumber(event.target.value);
    if (event.target.value) {
      setErrors((prev) => ({ ...prev, gstin: false }));
    }
  };

  const handleNext = (flag_click) => {
    if (flag_click === 1) {
      // Only move next if both are verified
      if (fssaiVerified !== null && gstVerified !== null) {
        if (fssaiVerified && gstVerified) {
          handleSaveChangesdynamic();
        }
      }
    } else {
      handleSaveChangesdynamic();
    }
  };

  const verifyFssai = async () => {
    setIsFssaiLoading(true);
    const form_data = new FormData();
    form_data.append("registration_no", fssaiNumber);
    await server_post_data(fssai_verification_combined, form_data)
      .then(async (Response) => {
        console.log(Response.data);
        if (Response.data.error) {
          handleError(Response.data.message);
          setFssaiVerified(false);
        } else {
          try {
            setFssaiActive(
              Response.data[0].result.source_output.company_details.validity
            );
            setFssaiVerified(true);
          } catch (err) {
            setFssaiVerified(false);
          }
        }
        setIsFssaiLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsFssaiLoading(false);
      });
  };

  const verifyGSTIN = async () => {
    setIsGstLoading(true);
    const form_data = new FormData();
    form_data.append("gstin", gstinNumber);
    await server_post_data(gst_verification_combined, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
          setGstVerified(false);
        } else {
          try {
            setGstActive(Response.data[0].result.source_output.gstin_status);
            setGstVerified(true);
          } catch (err) {
            setGstVerified(false);
          }
        }
        setIsGstLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsGstLoading(false);
      });
  };

  const handleSaveChangesdynamic = async () => {
    // You may wish to add a loader here as well if desired
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    form_data.append("restaurant_fssai", fssaiNumber);
    form_data.append("restaurant_gstin", gstinNumber);
    form_data.append("step_save", "6");
    await server_post_data(sava_onboarding_form, form_data)
      .then(async (Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          nextStep();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    master_data_get();
  }, []);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const form_data = new FormData();
    form_data.append("owner_staff_id", staff_id);
    form_data.append("default_restaurant_id", default_restaurant_id_staff);
    await server_post_data(get_last_resturant_data_save, form_data)
      .then((Response) => {
        if (Response.data.error) {
          handleError(Response.data.message);
        } else {
          if (Response.data.message.restaurant_data.length > 0) {
            setrestaurantData(Response.data.message.restaurant_data[0]);
            setFssaiNumber(
              Response.data.message.restaurant_data[0].restaurant_fssai
            );
            setGstinNumber(
              Response.data.message.restaurant_data[0].restaurant_gstin
            );
          }
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (fssaiVerified && gstVerified && fssaiNumber && gstinNumber) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [fssaiVerified, gstVerified, fssaiNumber, gstinNumber]);

  return (
    <div className="mt-3">
      <div className="containerRight">
        <div className="rightHeading">
          <div className="d-flex BackHeading">
            <button onClick={prevStep}>
              <img src={backButton} alt="Back Button" />
            </button>
            <h3>Restaurant Documents</h3>
          </div>
          <p>Please verify FSSAI Number & GSTIN Number</p>
        </div>
        <div className="overflowHeight">
          <div className="col-sm-9">
            <div
              className={`input-group Verificationcontainer ${
                errors.fssai ? "input-error" : ""
              }`}
            >
              <input
                type="text"
                minLength={15}
                maxLength={15}
                onChange={handleFssaiChange}
                className="form-control"
                placeholder="FSSAI Number"
                name="FSSAIName"
                value={fssaiNumber}
              />

              <div className="input-group-append">
                <button
                  className="btn btn-success VerificationBttn"
                  type="button"
                  onClick={verifyFssai}
                >
                  {isFssaiLoading ? (
                    <span>Verifying...</span>
                  ) : (
                    <span>Verify</span>
                  )}
                </button>
              </div>
            </div>
          </div>
          {errors.fssai && (
            <span className="error-message">FSSAI Number is required</span>
          )}
          {/* Show verification result only when not loading */}
          {fssaiVerified && !isFssaiLoading && (
            <span className="successMessage">Verified</span>
          )}
          {!fssaiVerified && fssaiVerified !== null && !isFssaiLoading && (
            <span className="error-message">
              FSSAI Number is Invalid or In-Active
            </span>
          )}

          <div className="col-sm-9 mt-3">
            <div
              className={`input-group Verificationcontainer ${
                errors.gstin ? "input-error" : ""
              }`}
            >
              <input
                type="text"
                maxLength={15}
                onChange={handleGstinChange}
                className="form-control"
                placeholder="Enter GSTIN Number"
                name="GSTINName"
                value={gstinNumber}
              />

              <div className="input-group-append">
                <button
                  className="btn btn-success VerificationBttn"
                  type="button"
                  onClick={verifyGSTIN}
                >
                  {isGstLoading ? (
                    <span>Verifying...</span>
                  ) : (
                    <span>Verify</span>
                  )}
                </button>
              </div>
            </div>
          </div>
          {errors.gstin && (
            <span className="error-message">GSTIN Number is required</span>
          )}
          {gstVerified && !isGstLoading && (
            <span className="successMessage">Verified</span>
          )}
          {!gstVerified && gstVerified !== null && !isGstLoading && (
            <span className="error-message">
              GSTIN Number is Invalid or In-Active
            </span>
          )}

          <div className="footerbtttn fixNextButton">
            <div className="container-lg d-flex justify-content-end gap-3">
              <div className="skipbutton" onClick={() => handleNext(2)}>
                <button>Skip</button>
              </div>

              <div
                className={`NextButton  ${
                  isFormValid ? "nonEmptyBttn" : " emptyFiled"
                }`}
                style={{ marginTop: "0px" }}
              >
                <div className="container-lg d-flex justify-content-end">
                  <button onClick={() => handleNext(1)}>Next</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboadingstep6;

import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Mobilefotter from "./Mobilefotter.js";
import {
  server_post_data,
  blog_details_website,
} from "../ServiceConnection/serviceconnection";
import { inputdateformateChange } from "../CommonJquery/CommonJquery";
import { useLocation } from "react-router-dom";
import DOMPurify from "dompurify";
import "./Css/Blog2.css";

const Blog2 = () => {
  const location = useLocation();
  const currentUrl = location.pathname.substring(1);
  const [isMobile, setIsMobile] = useState(false);
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [blogs, setBlogs] = useState(false);

  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    const fd = new FormData();
    fd.append("current_url", "/" + currentUrl);

    try {
      const response = await server_post_data(blog_details_website, fd);
      console.log(response.data.message);
      if (response.data.error) {
        alert(response.data.message);
      } else {
        setBlogs(response.data.message.data_blog[0]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setshowLoaderAdmin(false);
    }
  };

  useEffect(() => {
    master_data_get(); // Call the function when the component mounts or when currentUrl changes
  }, [currentUrl]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 767px)");
    setIsMobile(mediaQuery.matches);

    const handleChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addListener(handleChange);
    return () => {
      mediaQuery.removeListener(handleChange);
    };
  }, []);

  return (
    <>
      <Header />
      <div className="blog2_section">
        <div className="container">
          <div className="row blog2_section_heading headingMargin">
            <h1>{blogs.title_name}</h1>
          </div>
          <div className="blog_heading_name_date">
            <p>{blogs.author}</p>
            <p>|</p>
            <p>{inputdateformateChange(blogs.entry_date)}</p>
          </div>
          <div className="main_picture_blog2 m-auto">
            {isMobile ? (
              <img src={blogs.image_name} alt="img1" />
            ) : (
              <img src={blogs.image_name} alt="img1" />
            )}
          </div>

          <div className="col-md-10 m-auto">
            <div className="mt-5" style={{ textAlign: "justify" }}>
              <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(blogs.description),
                }}
                style={{ lineHeight: "27px", color: "#535969" }}
              />
            </div>
            <div className="col-lg-3 mt-5">
              <p className="writterBy">Written By:</p>
              <h5>{blogs.author}</h5>
              <p style={{ fontSize: "14px", color: "var(--text-grey)" }}>
                {blogs.designation}
              </p>
            </div>
          </div>
          {/* <div className="list_your_venue_blog2Page">
            <DownloadApp />
          </div> */}
        </div>
      </div>
      <Mobilefotter />
      <Footer />
    </>
  );
};

export default Blog2;

import React, { useState, useEffect } from "react";
import Header from "./Header";
import Mobilefotter from "./Mobilefotter.js";
import Footer from "./Footer";
import DOMPurify from "dompurify";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import rightTickGreen from "../Assets/rightTickGreen.png";
import circleIcn from "../Assets/circleIcn.svg";
import {
  server_post_data,
  get_terms_privacy_data,
} from "../ServiceConnection/serviceconnection";
const PrivacyPolicy = () => {
  const [showLoaderAdmin, setshowLoaderAdmin] = useState(false);
  const [policy, setPolicy] = useState();
  const master_data_get = async () => {
    setshowLoaderAdmin(true);
    let fd = new FormData();
    await server_post_data(get_terms_privacy_data, fd)
      .then((Response) => {
        console.log(Response.data.message[0].privacy_policy);
        if (Response.data.error) {
          alert(Response.data.message);
        } else {
          setPolicy(Response.data.message[0].privacy_policy);
        }
        setshowLoaderAdmin(false);
      })
      .catch((error) => {
        setshowLoaderAdmin(false);
      });
  };
  useEffect(() => {
    master_data_get();
  }, []);
  return (
    <div className="howitworks_wrapper">
      <Header />
      <div className=" container-lg headerSection_howitWorks col-lg-12">
        <div className="terms_imageContainer">
          <h3>Privacy Policy</h3>
        </div>
      </div>
      <div className="container-lg">
        <div className="ContrrTnC">
          {" "}
          <div className="headingAgremnt">
            <h3>Last Reviewed Date: [01/01/2025]</h3>
          </div>
          <div className="topContnt">
            <p>
              This <span>Privacy Policy</span>("Policy")explains how
              <span>
                {" "}
                <a
                  href="https://www.triosoft.ai/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Triosoft Technologies Pvt Ltd
                </a>{" "}
              </span>
              (“Company,” “Dfoodo,” “we,” “us,” or “our”) collects, uses,
              discloses, and transfers your information when you access Dfoodo’s
              website and mobile applications (the “Services”).
            </p>
            <p>
              This Policy is a <span> part of the Terms of Use</span> By using
              Dfoodo, you
              <span>agree </span> to the collection, storage, and processing of
              your information as described in this Policy.
            </p>
          </div>
          <div className="centerConttntt">
            <h2>1. Scope & Applicability</h2>
            <p>This Policy applies to all individuals who:</p>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="rightTickGreen"></img>
              <span> Access, browse, or use Dfoodo's Services (“Users”)</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="rightTickGreen"></img>
              <span>Use Dfoodo to make restaurant reservations</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="rightTickGreen"></img>
              <span> Register and create an account on Dfoodo</span>
            </div>
            <div className="d-flex flex-column lablClsss">
              {" "}
              <label className="mt-2" style={{ fontWeight: "700" }}>
                You or "Your" refers to the User. "We" or "Our" refers to
                Dfoodo.
              </label>
              <label className="mt-1" style={{ fontWeight: "700" }}>
                This Policy is subject to changes, so please review it
                periodically.
              </label>
            </div>
          </div>
          <div className="centerConttntt">
            <h2>2. Information We Collect</h2>

            <ul>
              <li>Dfoodo collects the following types of information: </li>
            </ul>
            <p className="firstPtegPrivacy">
              2.1 Information Supplied by Users
            </p>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Full Name</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span> Mobile Number (used for OTP verification)</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Email Address (optional)</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span> Date of Birth (optional)</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Preferred Restaurant Preferences</span>
            </div>
            <ul>
              <li>Social Media Login</li>
              <li>
                If you register using Google, Facebook, or Apple ID, we may
                receive your public profile information.
              </li>
            </ul>
            <p className="firstPtegPrivacy">
              2.2 Information Collected Automatically
            </p>
            <ul>
              <li>
                We use cookies and tracking pixels to personalize your
                experience.
              </li>
              <li>
                Cookies help us remember your preferences and optimize
                restaurant recommendations.
              </li>
            </ul>
            <label>
              We collect certain information automatically when you use our
              Services:
            </label>
            <ul>
              <li>
                <span>Device Information</span>:Mobile model, OS version, app
                version.
              </li>
              <li>
                <span>Log Data</span>:IP address, browser type, timestamps.
              </li>
              <li>
                <span>Location Data</span>:Used for restaurant recommendations.
              </li>
              <li>
                <span>Usage Data</span>: Features you use, booking history.
              </li>
            </ul>
            <p className="firstPtegPrivacy">
              2.3 Cookies & Tracking Technologies
            </p>
            <ul>
              <li>
                We use cookies and tracking pixels to personalize your
                experience.
              </li>
              <li>
                Cookies help us remember your preferences and optimize
                restaurant recommendations.
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>3. 3. How We Use Your Information</h2>
            <label>Dfoodo uses your information for:</label>
            <p className="firstPtegPrivacy3">
              3.1 Providing & Improving Services
            </p>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span> Enabling table reservations at partner restaurants</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Sending reservation confirmations & reminders</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Providing customer support</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Personalizing recommendations</span>
            </div>
            <p className="firstPtegPrivacy3">3.2 Marketing & Promotions</p>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Sending promotional emails and offers</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Notifying users about discounts & new features</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Users can opt-out of marketing emails at any time.</span>
            </div>
            <p className="firstPtegPrivacy3">3.3 Security & Fraud Prevention</p>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Monitoring for suspicious activities</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Preventing fraudulent reservations & fake reviews</span>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Protecting user data with encryption</span>
            </div>
          </div>
          <div className="centerConttntt3">
            <h2>4. Data Sharing & Disclosure</h2>
            <div className="fouthHad">
              <p className="firstPteg">4.1 With Partner Restaurants</p>{" "}
            </div>
            <ul>
              <li>
                We share reservation details with the restaurant to confirm your
                booking.
              </li>
              <li>Restaurants do not receive your personal contact details.</li>
            </ul>
            <p className="firstPteg">4.2 With Third-Party Service Providers</p>{" "}
            <ul>
              We work with payment processors, analytics providers, and cloud
              hosting services. These providers follow strict data protection
              guidelines.
            </ul>
            <p>4.3 For Legal Compliance</p>{" "}
            <ul>
              Dfoodo may disclose data if required by
              <li>
                <span>Law enforcement agencies</span>
              </li>
              <li>
                <span>Regulatory authorities</span>
              </li>
              <li>
                <span>Legal proceedings</span>
              </li>
            </ul>{" "}
          </div>
          <div className="centerConttntt3">
            <h2>5. Data Security Measures</h2>
            <li>We take strong security measures to protect your data</li>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>End-to-end encryption</span>
              <label> for payment & login data.</label>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Secure cloud storage</span>
              <label>with restricted access.</label>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Regular security audits</span>
              <label>to prevent unauthorized access.</label>
            </div>
            <label className="mt-2">
              However, no method of transmission over the Internet is 100%
              secure. Users should keep their login credentials private.
            </label>
          </div>
          <div className="centerConttntt3">
            <h2>6. User Rights & Controls</h2>
            <p className="firstPteg">
              Dfoodo respects your privacy rights. You can
            </p>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span>Access & update your personal data </span>
              <label>in your profile.</label>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span> Request deletion of your account</span>
              <label>by contacting support.</label>
            </div>
            <div className="grenMArkicnnn">
              <img src={rightTickGreen} alt="circleIcn"></img>{" "}
              <span> Manage notifications & marketing preferences. </span>
            </div>

            <label>
              To make a request, email{" "}
              <a href="mailto:support@triosoft.ai">support@triosoft.ai</a>.
            </label>
          </div>
          <div className="centerConttntt3">
            <h2>7.Data Retention</h2>
            <p className="firstPteg">
              We store user data only as long as necessary, based on:
            </p>
            <ul>
              <li>Legal & regulatory requirements</li>
              <li>User activity & engagement</li>
              <li>Business & security needs</li>
            </ul>
            <label>
              After account deletion, data is removed permanently from our
              servers within 60 days.
            </label>
          </div>
          <div className="centerConttntt3">
            <h2>8. Third-Party Links & Advertisements</h2>
            <ul>
              <li>
                {" "}
                Dfoodo may display third-party links and ads. Clicking on these
                links may lead to external websites.
              </li>
              <li>
                Dfoodo is not responsible for third-party privacy practices.
                Users should review their respective privacy policies.
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>9. Children's Privacy</h2>

            <ul>
              {" "}
              <li>
                <span>Dfoodo is not intended for users under 18.</span> belong
              </li>
              <li>
                <span>We do not knowingly collect data from minors.</span>
              </li>
              <li>
                <span>
                  If we discover a minor’s data, we delete it immediately.{" "}
                </span>
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>10. Policy Updates & Changes </h2>
            <p className="firstPteg">
              Dfoodo reserves the right to update this Privacy Policy.{" "}
            </p>
            <ul>
              <li>
                <span> Major updates will be notified via email.</span>
              </li>
              <li>
                <span>
                  Users should check this page regularly for any changes.
                </span>
              </li>
              <li>
                <span>
                  Continued use of Dfoodo constitutes acceptance of updates.
                </span>
              </li>
            </ul>
          </div>
          <div className="centerConttntt3">
            <h2>11. Contact Information</h2>
            <p className="firstPteg">
              For any privacy concerns, complaints, or questions, contact:
            </p>
            <div className="contact-info">
              <div className="emailLbll">
                <a
                  href="mailto:support@triosoft.ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaEnvelope size={13} /> Email :{" "}
                  <span> support@triosoft.ai </span>
                </a>
              </div>
              <div className="emailLbll">
                <a
                  href="tel:+917880016622"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaPhone size={13} /> Phone : <span> +91 7880016622 </span>
                </a>
              </div>
              <div className="emailLbll">
                <a
                  href="https://www.google.com/maps?q=Triosoft+Technologies+Pvt+Ltd,+Bhopal,+India"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaMapMarkerAlt size={13} /> Company Address:{" "}
                  <span>
                    {" "}
                    Triosoft Technologies Pvt Ltd, Bhopal (M.P.), India{" "}
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Mobilefotter />
      <Footer />
    </div>
  );
};
export default PrivacyPolicy;
